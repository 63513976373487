<template>
  <section class="subscriptions-page">
    <flash-message></flash-message>
    <template v-if="expiredSubscriptions.length > 0">
      <h2 class="page-subtitle">Failed Renewals</h2>
      <subscription-box v-for="(subscription, index) in expiredSubscriptions"
                        :key="subscription.id"
                        :is-current="true"
                        :subscription="subscription"
                        :class="{'subscription-box--last': index === expiredSubscriptions.length - 1}"></subscription-box>
    </template>
    <template v-if="currentSubscriptions.length > 0">
      <h2 class="page-subtitle">Current Subscriptions</h2>
      <subscription-box v-for="(subscription, index) in currentSubscriptions"
                        :key="subscription.id"
                        :is-current="true"
                        :subscription="subscription"
                        :class="{'subscription-box--last': index === currentSubscriptions.length - 1}"></subscription-box>
    </template>
    <template v-if="availableSubscriptions.length > 0">
      <h2 class="page-subtitle mt-40" v-if="currentSubscriptions.length > 0 || expiredSubscriptions.length > 0">Expand your library</h2>
      <subscription-box v-for="(subscription, index) in availableSubscriptions"
                        :key="subscription.id"
                        :is-current="false"
                        :subscription="subscription"
                        :class="{'subscription-box--last': index === availableSubscriptions.length - 1}"></subscription-box>
    </template>
  </section>
</template>

<script>
import _ from 'lodash';
import { mapState } from 'vuex';
import SubscriptionBox from '@/components/SubscriptionBox';
import PromocodeMixin from '@/components/mixins/PromocodeMixin';
import SubscriptionMixin from '@/components/mixins/SubscriptionMixin';

export default {
  name: 'subscriptions',
  mixins: [PromocodeMixin, SubscriptionMixin],
  components: {
    SubscriptionBox,
  },
  data() {
    return {
      allSubscriptions: [],
    };
  },
  mounted() {
    this.$bus.$emit('set-title', { title: 'subscriptions' });
    this.$nextTick(() => {
      this.getSubscriptions(this.queryCode)
        .then((packages) => {
          this.allSubscriptions = packages;
        })
        .catch((error) => {
          this.flashMessage(error, 'error');
        });
    });
  },
  computed: {
    ...mapState(['queryCode']),
    currentSubscriptions() {
      return _.filter(this.allSubscriptions, (subscription) => subscription.has_subscription);
    },
    availableSubscriptions() {
      return _.filter(this.allSubscriptions, (subscription) => !subscription.has_subscription && !subscription.is_awaiting_renewal);
    },
    expiredSubscriptions() {
      return _.filter(this.allSubscriptions, (subscription) => subscription.is_awaiting_renewal);
    },
  },
};
</script>
