<template>
  <div class="stripe-card">
    <flash-message target="stripe"></flash-message>
    <ValidationObserver v-slot="{ handleSubmit }" slim>
      <form ref="observer" @submit.prevent="handleSubmit(validateForm)" class="form">
        <ValidationProvider rules="required" name="Name" v-slot="{ errors }" tag="div" class="stripe-card__fieldset">
          <label for="name" class="stripe-card__label">Name</label>
          <input name="name" id="name" type="text" v-model="nameOnCard" class="stripe-card__field stripe-card__input" :class="{ 'stripe-card__input--invalid' : errors[0] }">
          <span v-if="errors" class="stripe-card__field--errors">{{ errors[0] }}</span>
        </ValidationProvider>
        <div class="stripe-card__fieldset">
          <label for="card-number" class="stripe-card__label">Card Number</label>
          <div id="card-number" class="stripe-card__field stripe-card__input empty"></div>
          <span id="brand-icon" class="stripe-card__icon stripe-card__icon--credit-card"></span>
        </div>
        <div class="stripe-card__fieldset">
          <div class="row">
            <div class="col">
              <label for="card-expiry" class="stripe-card__label">Expiry</label>
              <div id="card-expiry" class="stripe-card__field stripe-card__input empty"></div>
            </div>
            <div class="col">
              <label for="card-cvc" class="stripe-card__label">CVC</label>
              <div id="card-cvc" class="stripe-card__field stripe-card__input empty"></div>
            </div>
          </div>
        </div>
        <div class="stripe-card__fieldset" v-if="addSaveOption">
          <div class="row">
            <div class="col">
              <div class="stripe-card__checkbox">
                <label for="save-card" class="stripe-card__label">Save Card</label>
                <input type="checkbox" id="save-card" name="save-card" :v-model="saveCard"
                       @change="saveCard = $event.target.checked">
              </div>
            </div>
          </div>
        </div>
        <div class="stripe-card__fieldset" v-if="addSaveAsDefaultOption">
          <div class="row">
            <div class="col">
              <div class="stripe-card__checkbox">
                <label for="save-card-as-default" class="stripe-card__label">Save card as default</label>
                <input type="checkbox" id="save-card-as-default" name="save-card-as-default" :v-model="saveCardAsDefault" :checked="checkSaveAsDefaultOption"
                       @change="saveCardAsDefault = $event.target.checked">
              </div>
            </div>
          </div>
        </div>
        <div class="stripe-card__errors" v-if="formIsInvalid">
          <ul class="stripe-card__errors-list">
            <li class="stripe-card__errors-list-item" v-if="stripeErrors.cardCvc">
              <i class="fa fa-exclamation-triangle"></i>
              {{ stripeErrors.cardCvc }}
            </li>
            <li class="stripe-card__errors-list-item" v-if="stripeErrors.cardExpiry">
              <i class="fa fa-exclamation-triangle"></i>
              {{ stripeErrors.cardExpiry }}
            </li>
            <li class="stripe-card__errors-list-item" v-if="stripeErrors.cardNumber">
              <i class="fa fa-exclamation-triangle"></i>
              {{ stripeErrors.cardNumber }}
            </li>
          </ul>
        </div>
        <div class="stripe-card__errors" v-if="errorResponse">
          {{ errorResponse }}
        </div>
        <v-button class="btn btn-primary stripe-card__button" @click="processCard" group="stripe">
          <p>{{ buttonText }}</p>
          <p v-if="additionalButtonText">{{ additionalButtonText }}</p>
        </v-button>
        <div class="stripe-card__info"><fa icon="lock"></fa><span class="ml-1">Your payment data is encrypted and secure.</span></div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { validate } from 'vee-validate';
import FormMixin from '@/components/mixins/FormMixin';
import Button from '@/components/utils/Button';

export default {
  name: 'stripe-card',
  props: {
    addSaveOption: {
      required: false,
      default: false,
      type: Boolean,
    },
    addSaveAsDefaultOption: {
      required: false,
      default: false,
      type: Boolean,
    },
    checkSaveAsDefaultOption: {
      required: false,
      default: false,
      type: Boolean,
    },
    buttonText: {
      required: true,
    },
    additionalButtonText: {
      required: false,
      type: Text,
    },
  },
  mixins: [FormMixin],
  components: {
    'v-button': Button,
  },
  data() {
    return {
      cardBrandToCssClass: {
        visa: 'stripe-card__icon--visa',
        mastercard: 'stripe-card__icon--mastercard',
        amex: 'stripe-card__icon--american-express',
        discover: 'stripe-card__icon--discover',
        diners: 'stripe-card__icon--diners',
        jcb: 'stripe-card__icon--jcb',
        unknown: 'stripe-card__icon--credit-card',
      },
      elements: {},
      stripeErrors: {
        cardNumber: null,
        cardExpiry: null,
        cardCvc: null,
      },
      stripeEmpty: {
        cardNumber: true,
        cardExpiry: true,
        cardCvc: true,
      },
      nameOnCard: null,
      saveCard: false,
      saveCardAsDefault: false,
      errorResponse: null,
    };
  },
  mounted() {
    if (this.user) {
      this.nameOnCard = this.user.full_name;
    }
    this.saveCardAsDefault = this.checkSaveAsDefaultOption;
    this.initCard();
  },
  methods: {
    initCard() {
      const elements = this.stripe.elements({
        // Stripe's examples are localized to specific languages, but if
        // you wish to have Elements automatically detect your user's locale,
        // use `locale: 'auto'` instead.
        locale: 'en',
      });

      const elementStyles = {
        base: {
          color: '#606060',
          fontWeight: 500,
          fontSmoothing: 'antialiased',

          ':focus': {
            color: '#606060',
          },

          '::placeholder': {
            color: '#9BACC8',
          },

          ':focus::placeholder': {
            color: '#9BACC8',
          },
        },
        invalid: {
          ':focus': {
            color: '#ee2a41',
          },
          '::placeholder': {
            color: '#9BACC8',
          },
        },
      };

      const elementClasses = {
        focus: 'focus',
        empty: 'empty',
        invalid: 'invalid',
      };

      const cardNumber = elements.create('cardNumber', {
        style: elementStyles,
        classes: elementClasses,
      });
      cardNumber.mount('#card-number');
      cardNumber.on('change', (event) => {
        // Switch brand logo
        if (event.brand) {
          this.setBrandIcon(event.brand);
        }
      });

      const cardExpiry = elements.create('cardExpiry', {
        style: elementStyles,
        classes: elementClasses,
      });
      cardExpiry.mount('#card-expiry');

      const cardCvc = elements.create('cardCvc', {
        style: elementStyles,
        classes: elementClasses,
      });
      cardCvc.mount('#card-cvc');

      cardNumber.addEventListener('change', this.displayElementError);
      cardExpiry.addEventListener('change', this.displayElementError);
      cardCvc.addEventListener('change', this.displayElementError);

      this.elements = elements;
    },
    displayElementError(event) {
      this.errorResponse = null;
      this.stripeEmpty[event.elementType] = event.empty;
      if (event.error) {
        this.stripeErrors[event.elementType] = event.error.message;
      } else {
        this.stripeErrors[event.elementType] = null;
      }
    },
    setBrandIcon(brand) {
      const brandIconElement = document.getElementById('brand-icon');
      let cssClass = 'stripe-card__icon--credit-card';
      if (brand in this.cardBrandToCssClass) {
        cssClass = this.cardBrandToCssClass[brand];
      }
      for (let i = brandIconElement.classList.length - 1; i >= 0; i--) {
        brandIconElement.classList.remove(brandIconElement.classList[i]);
      }
      brandIconElement.classList.add('stripe-card__icon');
      brandIconElement.classList.add(cssClass);
    },
    processCard() {
      this.flashMessage(null, 'error', 'stripe');
      this.errorResponse = null;
      if (this.formIsInvalid()) {
        this.errorResponse = 'Missing or incorrect data.';
      } else {
        validate(this.nameOnCard, 'required').then((res) => {
          if (res.valid) {
            this.buttonGroupIsLoading('stripe', true);
            this.$emit('done', {
              card: this.elements.getElement('cardNumber'),
              name: this.nameOnCard,
              saveCard: this.saveCard,
              saveCardAsDefault: this.saveCardAsDefault,
            });
          } else {
            this.buttonGroupIsLoading('stripe', false);
            this.errorResponse = 'Missing or incorrect data.';
          }
        });
      }
    },
    formIsInvalid() {
      let hasError = false;
      Object.keys(this.stripeErrors)
        .sort()
        .forEach((key) => {
          if (this.stripeErrors[key] !== null) {
            hasError = true;
          }
        });
      Object.keys(this.stripeEmpty)
        .sort()
        .forEach((key) => {
          if (this.stripeEmpty[key] === true) {
            hasError = true;
          }
        });

      return hasError;
    },
  },
  computed: {
    ...mapState(['user', 'stripe']),
  },
};
</script>
