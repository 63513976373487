<template>
  <section class="product-page__wrapper">
    <flash-message></flash-message>
    <section class="product-page" v-if="ready && product">
      <!-- MODALS -->
      <modal title="Purchase" v-if="modal.isVisible('stripe')" @close="closePaymentModal" :show-close-button="true" class="modal__stripe">
        <stripe-card :buttonText="buttonText" :additionalButtonText="additionalButtonText" :add-save-option="true" @done="payWithNewCard" @close="closePaymentModal"></stripe-card>
      </modal>
      <modal title="Purchase" v-if="modal.isVisible('payment')" @close="closePaymentModal" :show-close-button="true" class="modal__success">
        <div class="form-card">
          <flash-message></flash-message>
          <ValidationProvider rules="required" name="Card" tag="div">
            <label for="card" class="form-card__label">Use existing payment method</label>
            <select name="card" id="card" class="form-card__select" v-model="cardId">
              <option v-for="card in customer.cards" :value="card.id" :key="card.id">**** **** **** {{ card.last4 }}
              </option>
            </select>
          </ValidationProvider>
          <p class="form-card__text form-card__text--center"><button class="btn btn-text" @click="modal.hide('payment') & modal.show('stripe')">Pay with new card</button></p>
          <v-button class="btn btn-primary btn-block" @click="payWithExistingCard(cardId)" group="payment">
            <p>{{ buttonText }}</p>
            <p>{{ additionalButtonText }}</p>
          </v-button>
        </div>
      </modal>
      <!-- /MODALS -->
      <div class="product-header">
        <div class="product-header__title">{{ product.title }}</div>
        <template v-if="product.has_purchased || justPurchased">
          <div class="product-header__status sm-none">
            <div class="product-header__status--inner">
              purchased
            </div>
          </div>
        </template>
      </div>
      <div class="product-description">{{ product.description }}</div>
      <template v-if="!product.has_purchased || justPurchased">
        <promocode-form v-model="promocodeForm" @validated="callPreviewPromocode(promocodeForm.code)" v-if="!hidePromocodeForm"></promocode-form>
        <div class="promocode__summary" v-else>
          <p v-html="promocodePrettyDescription"></p> <v-button class="btn btn-primary" group="promocode" @click="cancelPromocode">cancel</v-button>
        </div>
        <div class="product-badges" v-if="!justPurchased">
          <product-badge :product="product" @click="showPaymentModal" buttonText="Pay"></product-badge>
        </div>
      </template>
      <template v-if="product.has_purchased || justPurchased">
        <div class="product-thanks">
          <span v-if="justPurchased">Thanks, your purchase went through successfully. Happy reading!</span>
          <span v-if="!justPurchased && product.purchase_type == 'book_multi'">You have already purchased this collection, we hope you enjoy all the wonderful stories within.</span>
          <span v-if="!justPurchased && product.purchase_type == 'book_single'">You have already purchased this book. Happy reading!</span>
          <div><router-link to="/my/products" class="product-thanks__link">Get more books</router-link></div>
        </div>
      </template>
      <template v-if="product.collection">
        <div class="product-upsell">
          <span class="product-upsell__message"><fa icon="exclamation"></fa> Save money by purchasing this book as a collection</span>
          <product-box :key="product.collection.id"
                      :product="product.collection"
                      class="product-box--last"></product-box>
        </div>
      </template>
    </section>
  </section>
</template>

<script>
import { mapState } from 'vuex';
import _ from 'lodash';
import PromocodeForm from '@/components/forms/PromocodeForm';
import ProductMixin from '@/components/mixins/ProductMixin';
import CustomerMixin from '@/components/mixins/CustomerMixin';
import ProductBadge from '@/components/ProductBadge';
import StripeCard from '@/components/StripeCard';
import Modal from '@/components/utils/Modal';
import ModalJS from '@/components/classes/Modal';
import Button from '@/components/utils/Button';
import ProductBox from '@/components/ProductBox';
import UserMixin from '@/components/mixins/UserMixin';

export default {
  name: 'product',
  mixins: [ProductMixin, CustomerMixin, UserMixin],
  components: {
    PromocodeForm,
    ProductBadge,
    Modal,
    StripeCard,
    ProductBox,
    'v-button': Button,
  },
  data() {
    return {
      product: null,
      ready: false,
      promocodeForm: {
        code: null,
      },
      previewingPromocode: false,
      modal: new ModalJS({
        stripe: false,
        payment: false,
      }),
      customer: null,
      cardId: null,
      justPurchased: false,
    };
  },
  mounted() {
    this.getCustomer()
      .then((customer) => {
        this.customer = customer;
        this.cardId = this.defaultCardId;
      })
      .catch(() => {
      })
      .finally(() => {
        this.ready = true;
      });
    this.init();
    document.body.classList.remove('modal-open');
  },
  methods: {
    init(code = null, cb = null) {
      const slugParts = this.$route.params.slug.split('-');
      const productId = slugParts[slugParts.length - 1];
      this.getProduct(productId, code)
        .then((product) => {
          this.product = product;
        })
        .catch((error) => {
          this.flashMessage(error, 'error');
        })
        .finally(() => {
          if (cb !== null && typeof cb === 'function') {
            cb();
          }
        });
    },
    callPreviewPromocode() {
      this.flashMessage();
      this.previewingPromocode = true;
      this.buttonGroupIsLoading('promocode', true);
      this.init(this.promocodeForm.code, () => {
        if (this.product.purchase_data.is_valid_promocode === false) {
          this.flashMessage('This promotion is not valid on this product.', 'error');
        }
        this.buttonGroupIsLoading('promocode', false);
      });
    },
    cancelPromocode() {
      this.buttonGroupIsLoading('promocode', true);
      this.init(null, () => {
        this.resetPromocodeForm();
      });
    },
    resetPromocodeForm() {
      this.previewingPromocode = false;
      this.promocodeForm.code = null;
      this.buttonGroupIsLoading('promocode', false);
    },
    showPaymentModal() {
      let modalToOpen = 'stripe';
      if (!_.isEmpty(this.customer) && !_.isEmpty(this.customer.cards)) {
        modalToOpen = 'payment';
      }
      this.modal.show(modalToOpen);
    },
    closePaymentModal() {
      this.modal.hide('stripe');
      this.modal.hide('payment');
    },
    payWithExistingCard(cardId) {
      this.buttonGroupIsLoading('payment', true);
      const args = {};
      args.payment_method = cardId;

      this.startPurchase(args);
    },
    payWithNewCard({
      card, name, saveCard = false,
    }) {
      this.buttonGroupIsLoading('stripe', true);
      const args = {
        payment_method: {
          card,
          billing_details: {
            name,
          },
        },
      };

      this.startPurchase(args, saveCard);
    },
    startPurchase(args, saveCard = false) {
      const data = {};
      if (this.product.purchase_data.is_valid_promocode) {
        data.code = this.product.purchase_data.promocode.code;
      }

      if (saveCard) {
        args.setup_future_usage = 'off_session';
      }
      data.metadata = {
        products: this.product.id.toString(),
        code: data.code,
      };

      this.$api.post('/providers/stripe/payment_intents/purchases', data)
        .then((response) => {
          const clientSecret = response.data.client_secret;
          this.completePurchase(clientSecret, args, data);
        })
        .catch((error) => {
          this.flashMessage(error, 'error', 'stripe');
          this.buttonGroupIsLoading('all', false);
        });
    },
    completePurchase(clientSecret, args, data) {
      this.buttonGroupIsLoading('payment', true);
      this.stripe.confirmCardPayment(clientSecret, args)
        .then((result) => {
          if (result.error) {
            this.flashMessage(result.error.message, 'error', 'stripe');
            this.buttonGroupIsLoading('all', false);
          } else {
            if (result.paymentIntent.status === 'succeeded') {
              data.payment_intent_id = result.paymentIntent.id;
              this.$api.post('/products/purchase', data)
                .then((response) => {
                  this.closePaymentModal();
                  this.init();
                  this.flashMessage(response, 'success');
                  this.buttonGroupIsLoading('all', false);
                  this.justPurchased = true;
                  this.getUser();
                })
                .catch((error) => {
                  this.flashMessage(error, 'error', 'stripe');
                  this.buttonGroupIsLoading('all', false);
                });
            } else {
              this.closePaymentModal();
              this.flashMessage('An error occurred, please contact our team.', 'error');
              this.buttonGroupIsLoading('all', false);
            }
          }
        });
    },
  },
  computed: {
    ...mapState(['user', 'stripe']),
    hidePromocodeForm() {
      return this.previewingPromocode && this.product.purchase_data.is_valid_promocode;
    },
    currentPrice() {
      return this.extractCurrentPrice(this.product);
    },
    buttonText() {
      return `pay £${this.currentPrice}`;
    },
    promocodePrettyDescription() {
      const promocode = _.get(this.product, 'purchase_data.promocode', null);
      if (promocode === null) {
        return null;
      }

      let text = `<span>${promocode.code}: `;
      if (promocode.discount_type === 'percent_off') {
        text += `${promocode.discount_value}% discount`;
      } else if (promocode.discount_type === 'amount_off') {
        text += `£${promocode.discount_value} discount`;
      }
      text += '</span>';

      return text;
    },
  },
};
</script>
