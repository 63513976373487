<template>
  <div>
    <header class="whitespace-header" v-if="!hasSubscription && !isMyBooks">
      <div class="current-subscription__wrapper whitespace-header__inner" >
        <h3 class="current-subscription__package">
          <fa-layers class="exclamation-circle">
            <fa icon="circle" class="exclamation-circle__circle"></fa>
            <fa icon="exclamation" class="exclamation-circle__exclamation"></fa>
          </fa-layers>
            you currently have a basic account
        </h3>
        <p class="current-subscription__description">
          Fonetti is passionate about helping children learn to read and enjoy it! For this reason  we give all free accounts access to a few books that will always remain free to read.
        </p>
      </div>
    </header>
  </div>
</template>
<style lang="scss">
  .large-text {
    font-size: 1em;
  }
</style>
<script>
import { mapState } from 'vuex';

export default {
  name: 'v-header',
  computed: {
    ...mapState(['hasSubscription']),
    isMyBooks() {
      return this.$route.name === 'products' || this.$route.name === 'product';
    },
  },
};
</script>
