<template>
    <div class="d-flex align-items-center justify-content-center mt-100">
        <div v-if="status === 'failed'" class="d-flex flex-column align-items-center">
            <div class="flash-message flash-message__error">
                Login failed, please click the button below and manually login.
            </div>
            <a href="/" class="btn btn-primary">Go to login</a>
        </div>
        <div v-if="status === 'success'">
            <div class="flash-message flash-message__success">
                <fa icon="spinner" spin></fa><span class="ml-2">Please wait whilst we redirect you&hellip;</span>
            </div>
        </div>
        <div v-if="status === 'pending'"><fa icon="spinner" spin></fa><span class="ml-2">Logging you in &hellip;</span></div>
    </div>

</template>

<script>
import AuthMixin from '@/components/mixins/AuthMixin';

export default {
  name: 'autologin',
  mixins: [AuthMixin],
  data() {
    return {
      status: 'pending',
    };
  },
  mounted() {
    const { token } = this.$route.params;

    // Place the token into the store (so api interceptor can grab it)
    this.$store.commit('SET_TOKEN', token);

    // Get current user to see if the token is valid
    this.$api.get('users/me?include=subscriptions', { autoErrorHandling: false })
      .then((response) => {
        const { user } = response.data;
        if (user) {
          this.status = 'success';
          this.$store.commit('SET_USER', user);
          this.redirectAfterAuth({ name: 'subscriptions' });
        } else {
          this.status = 'failed';
        }
      }).catch(() => {
        this.status = 'failed';
      });
  },
};
</script>
