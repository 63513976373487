<template>
  <section class="sidebar">
    <header class="header">
      <router-link to="/" class="header__logo-link">
        <img src="../assets/img/fonetti_logo_v2.png" class="logo" alt="Fonetti">
      </router-link>
    </header>
    <ul class="sidebar__link--emphasis">
      <li class="sidebar__link"><a href="https://fonetti.com" target="_blank">Fonetti.com</a></li>
    </ul>
    <ul>
      <router-link to="/my/dashboard" class="sidebar__link" active-class="sidebar__link--active" tag="li"><a>Dashboard</a></router-link>
      <router-link to="/my/details" class="sidebar__link" active-class="sidebar__link--active" tag="li"><a>Account</a></router-link>
      <router-link to="/my/subscriptions" class="sidebar__link" active-class="sidebar__link--active" tag="li"><a>Subscriptions</a></router-link>
      <router-link to="/my/products" class="sidebar__link" active-class="sidebar__link--active" tag="li"><a>My Books</a></router-link>
      <router-link to="/my/payments" class="sidebar__link" active-class="sidebar__link--active" tag="li"><a>Payments</a></router-link>
      <li class="sidebar__link"><a @click="logout()">Logout</a></li>
    </ul>
    <ul>
      <li class="sidebar__link"><a href="https://www.fonetti.com/contact-us-at-fonetti" target="_blank">Contact Us</a></li>
      <li class="sidebar__link"><a href="https://www.fonetti.com/privacy-policy/" target="_blank">Privacy Policy</a></li>
    </ul>
    <v-footer></v-footer>
  </section>
</template>

<script>
import Footer from '@/components/Footer';
import AuthMixin from '@/components/mixins/AuthMixin';

export default {
  name: 'sidebar',
  mixins: [AuthMixin],
  components: {
    'v-footer': Footer,
  },
};
</script>
