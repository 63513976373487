<template>
  <div class="modal fade show" tabindex="-1" role="dialog" aria-labelledby="modal-label">
    <div class="modal-dialog modal__dialog" role="document">
      <div class="modal-content modal__content">
        <div class="modal-header modal__header">
          <button type="button" class="modal__button-close" data-dismiss="modal" aria-label="Close" @click="$emit('close')" v-if="showCloseButton"><fa icon="times-circle"></fa></button>
          <div class="modal__title">{{ title }}</div>
          <div class="modal__subtitle">{{ subtitle }}</div>
        </div>
        <div class="modal__wrapper">
          <div class="modal-body modal__body">
            <slot></slot>
          </div>
          <div class="modal__footer" v-if="hasFooterSlot">
            <slot name="footer"></slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Modal',
  props: {
    title: { required: true },
    subtitle: { required: false },
    showCloseButton: { required: false, default: true, type: Boolean },
  },
  computed: {
    hasFooterSlot() {
      return !!this.$slots.footer;
    },
  },
};
</script>
