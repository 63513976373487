<template>
  <footer class="footer">
    <!--
    <newsletter-modal :modal="modal"></newsletter-modal>
    <a href="https://itunes.apple.com/gb/app/fonetti/id1332609392?mt=8" target="_blank"><img src="../assets/img/app-store-white.svg" alt="Download on the App Store" class="footer__app-store"></a>
    <p>Available for iPad only</p>
    <v-button class="newsletter-button" @click="modal.show('newsletter')">Register your interest in other platforms</v-button>
    -->
    <div class="appstore-badges">
      <a href="https://app.fonetti.com" target="_blank"><img src="../assets/img/webapp-button.png" alt="Read on web" /></a>
      <a href="https://www.amazon.in/Auris-Tech-Ltd-Fonetti-read-aloud/dp/B0D5J6Q59X" target="_blank"><img src="../assets/img/amazon-app-store.svg" alt="Download from Amazon App Store" /></a>
      <a href="https://apps.apple.com/app/fonetti/id1332609392" target="_blank"><img src="../assets/img/app-store.svg" alt="Download on the app store"></a>
      <a href="https://play.google.com/store/apps/details?id=com.auristech.fonetti" target="_blank"><img src="../assets/img/google-play-badge.svg" alt="Download on the play store"></a>
    </div>
    <img src="../assets/img/logo-footer.png" alt="empowered by Auris" class="footer__logo fit-img">
  </footer>
</template>

<script>
import ModalJS from '@/components/classes/Modal';
// import Button from '@/components/utils/Button';
// import NewsletterModal from '@/components/NewsletterModal';

export default {
  name: 'v-footer',
  components: {
    // 'v-button': Button,
    // NewsletterModal,
  },
  data() {
    return {
      modal: new ModalJS({
        newsletter: false,
      }),
    };
  },
};
</script>

<style lang="scss" scoped>
  .appstore-badges {
    a {
      width: 80%;
      display:block;
      margin: 1em 0;
      img {
        width:100%;
      }
    }
  }
</style>
