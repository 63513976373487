import UserMixin from '@/components/mixins/UserMixin';
import Reader from '@/components/classes/Reader';

export default {
  mixins: [UserMixin],
  data() {
    return {
      currentReader: {},
    };
  },
  methods: {
    redirectIfUnownedReader(currentReader) {
      // redirect if reader id doesn't belong to current user
      if (currentReader === undefined) {
        this.$router.push('/my/dashboard');
      }
    },
    createReader(reader) {
      this.buttonGroupIsLoading('readers', true);
      this.$api.post('/readers', reader)
        .then(async () => {
          await this.getUser();
          this.$router.push('/my/dashboard');
        })
        .catch((error) => {
          this.flashMessage(error, 'error');
        })
        .finally(() => {
          this.buttonGroupIsLoading('readers', false);
        });
    },
    updateReader(reader, readerId) {
      this.buttonGroupIsLoading('readers', true);
      this.$api.post(`/readers/${readerId}`, reader)
        .then(async () => {
          await this.getUser();
          this.$router.push('/my/dashboard');
        })
        .catch((error) => {
          this.flashMessage(error, 'error');
        })
        .finally(() => {
          this.buttonGroupIsLoading('readers', false);
        });
    },
    destroyReader(readerId) {
      this.buttonGroupIsLoading('readersDelete', true);
      this.$api.delete(`/readers/${readerId}`)
        .then(async () => {
          await this.getUser();
          this.$router.push('/my/dashboard');
        })
        .catch((error) => {
          this.flashMessage(error, 'error');
        })
        .finally(() => {
          this.buttonGroupIsLoading('readersDelete', false);
        });
    },
    getReaderSummary(params) {
      this.$api.get(`statistics/readers/${this.currentReader.id}/progress`, { params })
        .then((response) => {
          const progress = response.data;
          this.currentReader.setProgress(progress);
          this.currentReader.setBookProgress(progress.reading_sessions);
        });
    },
  },
  mounted() {
    const { readerId } = this.$route.params;

    if (readerId) {
      const currentReader = this.$store.state.user.readers.find(({ id }) => id === Number(readerId));
      this.redirectIfUnownedReader(currentReader);
      if (currentReader !== undefined) {
        this.currentReader = new Reader(currentReader);
      }
    }
  },
};
