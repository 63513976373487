<template>
  <section class="reader-details-page">
    <div class="navigation-links">
      <router-link :to="{path: '/my/dashboard'}" class="btn btn-primary">Back to Profiles</router-link>
      <router-link :to="{path: `/my/readers/${currentReader.id}/edit`}" class="btn btn-primary">Edit</router-link>
    </div>
    <div class="reader-details mt-3">
      <div class="d-flex flex-column flex-lg-row justify-content-center justify-content-lg-between">
        <h2 class="text-left">{{currentReader.name}}</h2>
        <div class="btn-group daterange-select text-center">
          <button @click="timeFrame = 'all_time'" class="btn btn-primary" :class="{active: timeFrame === 'all_time'}">All Time</button>
          <button @click="timeFrame = 'week'" class="btn btn-primary" :class="{active: timeFrame === 'week'}">This Week</button>
          <button @click="timeFrame = 'month'" class="btn btn-primary" :class="{active: timeFrame === 'month'}">This Month</button>
        </div>
      </div>
      <div class="overall-stats d-flex justify-content-around mt-3">
        <StatWrapper :style="{color: 'white', 'font-size': '1.2em', 'line-height': 1.2}" :icon="'book'" :stat="{count: booksRead, label: 'Books'}"></StatWrapper>
        <StatWrapper :style="{color: 'white', 'font-size': '1.2em', 'line-height': 1.2}" :icon="'star'" :stat="{count: stars, label: 'Stars'}"></StatWrapper>
        <StatWrapper :style="{color: 'white', 'font-size': '1.2em', 'line-height': 1.2}" :icon="'clock'" :stat="{count: readingTime, label: ''}"></StatWrapper>
      </div>
      <div class="book-stats mt-3">
        <template v-for="(book, index) in bookProgress">
          <BookStats :book="book" :key="index"></BookStats>
        </template>
      </div>
    </div>
  </section>
</template>
<script>
import StatWrapper from '@/components/readers/StatWrapper';
import BookStats from '@/components/readers/BookStats';
import ReaderMixin from '@/components/mixins/ReaderMixin';
import moment from 'moment';

export default {
  mixins: [ReaderMixin],
  components: {
    StatWrapper,
    BookStats,
  },
  data() {
    return {
      timeFrame: 'all_time',
    };
  },
  computed: {
    booksRead() {
      return this.currentReader.progress ? this.currentReader.progress.books_read : 0;
    },
    stars() {
      return this.currentReader.progress ? this.currentReader.progress.stars : 0;
    },
    readingTime() {
      return this.currentReader.progress ? this.currentReader.progress.reading_time : 0;
    },
    bookProgress() {
      return this.currentReader.bookProgress || [];
    },
    params() {
      const currentDate = moment();
      let startTime = moment('2021-01-01');
      let endTime = currentDate;

      switch (this.timeFrame) {
        case 'month':
          startTime = currentDate.clone().startOf('month');
          endTime = currentDate.clone().endOf('month');
          break;
        case 'week':
          startTime = currentDate.clone().startOf('week');
          endTime = currentDate.clone().endOf('week');
          break;
        default:
          break;
      }
      return {
        start_time: startTime.format('YYYY-MM-DD'),
        end_time: endTime.format('YYYY-MM-DD'),
      };
    },
  },
  watch: {
    timeFrame() {
      this.getReaderSummary(this.params);
    },
  },
  mounted() {
    this.getReaderSummary(this.params);
  },
};
</script>
<style lang="scss" scoped>
  .reader-details-page {
    .navigation-links {
      display: flex;
      justify-content: space-between;
    }
    .reader-details {
      .daterange-select {
        .btn {
          border-radius: 0px;
          padding: 0 15px 3px 15px;
          &:first-child {
            border-radius: 15px 0px 0px 15px;
          }
          &:last-child {
            border-radius: 0px 15px 15px 0px;
          }
          &.active {
            background-color: #ffac33;
          }
        }
      }
    }
    a.btn:hover {
      color: white;
    }
  }
</style>
