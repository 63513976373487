export default {
  methods: {
    getProducts() {
      return new Promise((resolve, reject) => {
        const url = '/users/me/available_products';
        this.$api.get(url)
          .then((response) => {
            const { products } = response.data;
            resolve(products);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getProduct(id, code = null) {
      return new Promise((resolve, reject) => {
        let url = `products/${id}`;
        if (code !== null) {
          url += `?code=${code}`;
        }
        this.$api.get(url)
          .then((response) => {
            const { product } = response.data;
            resolve(product);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    extractCurrentPrice(product) {
      const purchaseData = product.purchase_data;
      if (purchaseData.discounted_price !== null) {
        return purchaseData.discounted_price;
      }
      return purchaseData.price;
    },
  },
};
