<template>
  <div class="wrapper wrapper--guest">
    <!-- <newsletter-modal :modal="modal"></newsletter-modal> -->
    <div class="row">

      <!-- Desktop header & sidebar -->
      <div class="col-md-3 sm-none">
        <div class="side-content">
          <div class="header">
            <router-link to="/" class="header__logo-link"><img src="../assets/img/fonetti_logo_v2.png" class="logo" alt="Fonetti">
            </router-link>
          </div>
          <div class="sm-none">
            <div class="appstore-badges">
              <a href="https://app.fonetti.com" target="_blank"><img src="../assets/img/webapp-button.png" alt="Read on web" /></a>
              <a href="https://www.amazon.in/Auris-Tech-Ltd-Fonetti-read-aloud/dp/B0D5J6Q59X" target="_blank"><img src="../assets/img/amazon-app-store.svg" alt="Download from Amazon App Store" /></a>
              <a href="https://apps.apple.com/app/fonetti/id1332609392" target="_blank"><img src="../assets/img/app-store.svg" alt="Download on the app store"></a>
              <a href="https://play.google.com/store/apps/details?id=com.auristech.fonetti" target="_blank"><img src="../assets/img/google-play-badge.svg" alt="Download on the play store"></a>
            </div>
            <!-- <p>Available for iPad only</p>
            <v-button class="newsletter-button" @click="modal.show('newsletter')">Register your interest in other platforms</v-button> -->
            <!-- <div class="badge">
              <div class="badge__header">
                <div class="badge__header-text">
                  First<br>month <span class="badge__header--emphasis">FREE!</span>
                </div>
                <div class="badge__header-icon">
                  <img src="../assets/img/single-icon.svg" alt="Single">
                </div>
              </div>
              <div class="badge__content">
                <span>single</span>
                <span class="badge__content--emphasis">£3.49</span>
                <span>per month</span>
              </div>
            </div> -->
          </div>
        </div>
      </div>

      <!-- Mobile header -->
      <div class="col-sm-12 d-md-none">
        <div class="header">
          <router-link to="/" class="header__logo-link"><img src="../assets/img/fonetti_logo_v2.png" class="logo" alt="Fonetti">
          </router-link>
        </div>
      </div>

      <!-- Content -->
      <div class="col-md-9 col-sm-12">
        <div class="main-content">
          <router-view></router-view>
        </div>
      </div>
    </div>

    <!-- Desktop footer (mobile footer would be better here too) -->
    <!-- TODO: This only needs showDesktop because I don't want to edit the css yet until I understand it more - This certainly doesn't need a v-if -->
    <div class="footer d-none d-md-flex" v-if="showDesktop">
      <img src="../assets/img/logo-footer.png" alt="empowered by Auris" class="footer__logo">
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ModalJS from '@/components/classes/Modal';
// import Button from '@/components/utils/Button';
// import NewsletterModal from '@/components/NewsletterModal';

export default {
  name: 'guest-layout',
  components: {
    // 'v-button': Button,
    // NewsletterModal,
  },
  data() {
    return {
      modal: new ModalJS({
        newsletter: false,
      }),
    };
  },
  computed: {
    ...mapGetters(['showMobile', 'showDesktop']),
  },
};
</script>

<style lang="scss" scoped>
  @import "src/assets/scss/variables";
  .appstore-badges {
    a {
      width: 80%;
      display:block;
      margin: 1em auto;
      img {
        width:100%;
      }
    }
  }
  .badge {
    width: 75%;
    background-color: $accent-color;
    color: $secondary-color;
    @include border-radius(10px);
    font-weight: 600;
    padding: 15px 15px;
    margin: 50px auto 0 auto;
    text-align: left;
    &__header {
      display: flex;
      justify-content: space-between;
      &-icon {
        width: 30%;
        text-align: center;
      }
      &-text {
        display: flex;
        flex-direction: column;
        line-height: 1.1em;
      }
      &--emphasis {
        font-variant: small-caps;
      }
    }
    &__content {
      display: flex;
      flex-direction: column;
      font-variant: small-caps;
      line-height: 1.6em;
      margin-top: 10px;
      &--emphasis {
        font-size: 3em;
        font-weight: bold;
      }
    }
  }

  @media only screen and (max-width: #{$large-device}px) {
    .badge {
      width: 100%;
    }
  }

  @media only screen and (max-width: #{$medium-device}px) {
    .badge {
      width: 100%;
      &__content {
        line-height: 1.2em;
        &--emphasis {
          font-size: 2em;
        }
      }
    }
  }
</style>
