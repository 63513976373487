<template>
  <div @change="handleChange($event)" class="btn-group btn-group-toggle" data-toggle="buttons">
    <legend v-if="label">{{label}}</legend>
    <label class="btn btn-secondary" v-for="(control, index) in controls" :key="index" :class="{active: control.isActive, wide: hasWideButtons, disabled: control.disabled || false }"
      v-tooltip.top-center="{content: control.disabled ? control.disabled_message || '' : '', trigger: 'hover'}">
      <input type="radio" :name="name" :id="control.value" autocomplete="off" :value="control.value"
        :disabled="control.disabled || false">{{control.text}}
    </label>
  </div>
</template>

<script>
import VToolTip from 'v-tooltip';

export default {
  name: 'segmented-controls',
  props: {
    name: {
      required: true,
      type: String,
    },
    controls: {
      required: true,
      type: Array,
    },
    label: {
      required: false,
      type: String,
    },
    hasWideButtons: {
      required: false,
      type: Boolean,
    },
  },
  components: {
    VToolTip, // eslint-disable-line
  },
  methods: {
    handleChange(e) {
      const labels = e.target.closest('.btn-group').getElementsByTagName('label');
      for (let i = 0; i < labels.length; i++) {
        labels[i].classList.remove('active');
      }
      e.target.closest('.btn').classList.add('active');
      this.$emit('update-value', e.target.value);
    },
  },
};
</script>

<style lang="scss" scoped>
  legend {
    font-size: 18px;
    font-weight: 300;
    line-height: 27px;
    font-family: "Source Sans Pro", Arial, sans-serif;
    text-size-adjust: 100%;
  }

  .btn-group {
    display: flex;
    justify-content: center;
    margin-top: 50px;
  }

  .btn {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0;
    flex: flex-grow;
    border-left: 1px solid #fff;
    border-right: none;
    padding: none;
    &.disabled {
      opacity: 0.7;
      background-color: #7c3a00;
      color: white;
      &:hover {
        background-color: #7c3a00;
        color: white;
      }
    }
    input {
      appearance: none;
      border-bottom: none;
      width: 100%;
      display: none;
    }
  }

  .btn:first-of-type {
    border-radius: 10px 0 0 10px;
    border-left: none;
  }

  .btn:last-child {
    border-radius: 0 10px 10px 0;
  }

  .active {
    background-color: #ffce4e;
    color: #7c3a00;
    border: 3px solid #fff;
  }

  .btn.active:first-of-type {
    border-left: 3px solid #fff;
  }

  .active:hover {
    background-color: #ffce4e;
  }

  .btn.wide {
    width: 220px;
  }
</style>
