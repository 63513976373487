const secondsToString = (seconds, args = {}) => {
  if (seconds < 60) {
    return `${seconds} ${seconds === 1 ? 'sec' : 'secs'}`;
  }
  const hours = Math.floor(seconds / 3600);
  const totalSeconds = seconds % 3600;
  const minutes = Math.floor(totalSeconds / 60);
  const leftSeconds = Math.floor(totalSeconds / 60);
  let string = '';
  if (hours >= 1) {
    string += `${hours} ${hours === 1 ? 'hr' : 'hrs'}${args.break_line ? '<br/>' : ' '}`;
  }
  if (minutes >= 1) {
    string += `${minutes} ${minutes === 1 ? 'min' : 'mins'}${args.break_line ? '<br/>' : ' '}`;
  }
  if (leftSeconds >= 1) {
    string += `${leftSeconds} ${leftSeconds === 1 ? 'sec' : 'secs'}`;
  }

  return string.trim();
};

export default class Reader {
  constructor(reader) {
    this.id = reader.id || null;
    this.age = reader.age || null;
    this.name = reader.name || '';
    this.avatar = reader.avatar || 'avatar_default';
    this.progress = {
      books_read: 0,
      stars: 0,
      reading_time_seconds: 0,
      reading_time: '0 secs',
    };
    this.bookProgress = [];
  }

  /**
   * @param {Object} Reader progress data
   */
  setProgress(data) {
    const readingTimeSeconds = data.total_reading_time || 0;
    this.progress = {
      books_read: data.total_books_read || 0,
      stars: data.total_stars || 0,
      reading_time_seconds: readingTimeSeconds,
      reading_time: secondsToString(readingTimeSeconds),
    };
  }

  setBookProgress(data) {
    this.bookProgress = data.map((book) => ({ ...book, reading_time: secondsToString(book.reading_time, { break_line: true }) }));
  }
}
