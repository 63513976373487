<template>
  <section class="account-page">
    <modal title="Are you sure?" v-if="modal.isVisible('deleteAccount')" @close="cancelDeleteAccount" :show-close-button="false" class="modal__warning">
        <p>Enter your password to confirm you would like to delete your account.</p>
        <p>Your account will be permanently deleted. This action is irreversible.</p>
        <p class="danger">If you have a subscription via your app store, you will need to cancel this separately otherwise you will still be charged.</p>
        <ValidationObserver v-slot="{ handleSubmit }" slim>
          <form ref="observer" @submit.prevent="handleSubmit(deleteAccount)" class="form-guest form-guest__dark form-account">
            <ValidationProvider :rules="`required`" name="password" v-slot="{ errors }" tag="div" class="form-guest__group">
                <input id="deleteUserPassword" type="password" name="password" placeholder="Password" v-model="forms.deleteUser.password">
              <span v-show="forms.deleteUser.validationEnabled && errors" class="form-guest__errors">{{ errors[0] }}</span>
            </ValidationProvider>
          </form>
        </ValidationObserver>
        <template v-slot:footer>
          <v-button class="btn btn-primary modal__button" group="delete" @click="deleteAccount()">Yes, I am sure</v-button>
          <button class="btn btn-cancel modal__button" @click="cancelDeleteAccount">No, don't delete my account.</button>
        </template>
    </modal>
    <flash-message></flash-message>
    <h2 class="table-title">Account</h2>
    <account-form v-model="forms.account" @validated="updateAccount" @delete-account="modal.show('deleteAccount')" :key="key"></account-form>
  </section>
</template>

<script>
import { mapState } from 'vuex';
import AccountForm from '@/components/forms/AccountForm';
import UserMixin from '@/components/mixins/UserMixin';
import AuthMixin from '@/components/mixins/AuthMixin';
import Modal from '@/components/utils/Modal';
import ModalJS from '@/components/classes/Modal';
import Button from '@/components/utils/Button';

export default {
  name: 'v-details',
  mixins: [UserMixin, AuthMixin],
  components: {
    AccountForm,
    Modal,
    'v-button': Button,
  },
  data() {
    return {
      key: 1,
      forms: {
        account: {
          email: null,
          first_name: null,
          last_name: null,
          password: null,
          update_password: false,
          receive_marketing_emails: false,
          weekly_digest_optin: false,
        },
        deleteUser: {
          password: null,
          validationEnabled: false,
        },
      },
      isSubscribed: false,
      modal: new ModalJS({
        deleteAccount: false,
      }),
    };
  },
  mounted() {
    this.buttonGroupIsLoading('account', true);
    this.$api.get('users/me?include=marketing_email_data')
      .then((response) => {
        const { user } = response.data;
        this.forms.account.email = user.email;
        this.forms.account.first_name = user.first_name;
        this.forms.account.last_name = user.last_name;
        this.isSubscribed = !!user.marketing_optin_at;
        this.forms.account.receive_marketing_emails = this.isSubscribed;
        this.forms.account.weekly_digest_optin = user.weekly_digest_optin_at !== null;
      })
      .catch((error) => this.flashMessage(error, 'error'))
      .finally(() => this.buttonGroupIsLoading('account', false));
  },
  methods: {
    cancelDeleteAccount() {
      this.modal.hide('deleteAccount');
      this.forms.deleteUser.email = null;
      this.forms.deleteUser.validationEnabled = false;
    },
    deleteAccount() {
      this.forms.deleteUser.validationEnabled = true;
      this.buttonGroupIsLoading('delete', true);
      this.$api.post('users/me/delete', { password: this.forms.deleteUser.password })
        .then(() => {
          this.buttonGroupIsLoading('delete', false);
          this.logout();
          this.buttonGroupIsLoading('account', false);
        })
        .catch((error) => {
          this.flashMessage(error, 'error');
          this.buttonGroupIsLoading('delete', false);
          this.forms.validationEnabled = false;
        })
        .finally(() => this.modal.hide('deleteAccount'));
    },
    updateAccount() {
      this.buttonGroupIsLoading('account', true);
      const data = {};
      data.email = this.forms.account.email;
      data.first_name = this.forms.account.first_name;
      data.last_name = this.forms.account.last_name;
      if (this.forms.account.update_password) {
        data.password = this.forms.account.password;
      }
      data.weekly_digest_optin = this.forms.account.weekly_digest_optin;
      this.$api.post('users/me', data)
        .then(() => {
          if (this.isSubscribed !== this.forms.account.receive_marketing_emails) {
            this.handleMarketingEmail(data)
              .then(() => {
                this.flashMessage('Your details have been saved.', 'success');
              })
              .catch((error) => {
                this.flashMessage(error, 'error');
              })
              .finally(() => {
                this.getUser().then((user) => {
                  this.isSubscribed = !!user.marketing_optin_at;
                });
                this.buttonGroupIsLoading('account', false);
              });
          } else {
            this.flashMessage('Your details have been saved.', 'success');
            this.getUser();
            this.buttonGroupIsLoading('account', false);
          }
        })
        .catch((error) => {
          this.flashMessage(error, 'error');
          this.buttonGroupIsLoading('account', false);
        })
        .finally(() => {
          this.forms.account.update_password = false;
          this.key++;
        });
    },
    // eslint-disable-next-line consistent-return
    handleMarketingEmail() {
      if (this.forms.account.receive_marketing_emails === true) {
        return new Promise((resolve, reject) => {
          this.$api.post('/newsletters/subscribers')
            .then(() => {
              resolve();
            })
            .catch((error) => reject(error));
        });
      }

      if (this.forms.account.receive_marketing_emails === false) {
        return new Promise((resolve, reject) => {
          this.$api.delete('/newsletters/subscribers')
            .then(() => {
              resolve();
            })
            .catch((error) => reject(error));
        });
      }
    },
  },
  computed: {
    ...mapState(['user']),
  },
};
</script>

<style lang="scss">
  .modal__button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

 .form-guest input {
    color: black;
  }
</style>
