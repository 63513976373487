import { mapState } from 'vuex';
import pluralize from 'pluralize';

const customDescription = {
  INRAC23: 'Annual subscription discounted for your first year',
};

export default {
  data() {
    return {
      promocode: null,
    };
  },
  methods: {
    getPromocode(code, targetType) {
      return new Promise((resolve, reject) => {
        this.$api.get(`promocodes/${code}${targetType ? `?target_type=${targetType}` : ''}`)
          .then((response) => {
            const { promocode } = response.data;
            resolve(promocode);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    previewPromocode(code, targetType) {
      return new Promise((resolve, reject) => {
        this.getPromocode(code, targetType)
          .then((promocode) => {
            this.promocode = promocode;
            this.$store.commit('SET_PREVIEW_PROMOCODE', promocode);
            resolve(promocode);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    clearPromocode() {
      this.promocode = null;
      this.$store.commit('SET_QUERY_CODE', null);
      this.$store.commit('SET_PREVIEW_PROMOCODE', null);
      this.$bus.$emit('promocode-cleared');
    },
    applyPromocode(code, subscription) {
      return new Promise((resolve, reject) => {
        this.$api.post('promocodes/apply', { promocode: code, subscription: subscription.payment_provider_subscription_id })
          .then((response) => {
            this.promocode = null;
            this.$store.commit('RESET_PROMOCODE');
            resolve(response);
          })
          .catch((error) => {
            this.promocode = null;
            this.$store.commit('RESET_PROMOCODE');
            reject(error);
          });
      });
    },
  },
  mounted() {
    if (this.queryCode !== null) {
      const query = { ...this.$route.query };
      query.promo = this.queryCode;
      this.$router.replace({ query })
        .then(() => {})
        .catch(() => {});
    }
  },
  computed: {
    ...mapState(['queryCode']),
    promocodePrettyDescription() {
      if (this.promocode === null) {
        return null;
      }

      if (customDescription[this.promocode.code]) {
        return `<span>${customDescription[this.promocode.code]}</span>`;
      }

      let text = `<span>${this.promocode.code}`;
      if (this.promocode.type === 'free_trial') {
        text += ' free trial';
      } else if (this.promocode.type === 'coupon') {
        text += ` discount ${this.promocode.discount_type === 'percent_off' ? `${this.promocode.discount_value}%` : `${this.selectedCurrency ? this.selectedCurrency.symbol : '£'}${this.promocode.discount_value}`} off`;
      }
      text += '</span>';
      if (this.promocode.target_type === 'purchase') {
        text += ' a book purchase';
      } else if (this.promocode.target_type === 'subscription') {
        if (this.promocode.duration_length > 0) {
          text += ` for ${pluralize(this.promocode.duration_unit, this.promocode.duration_length, true)}`;
        }
      }

      return text;
    },
  },
};
