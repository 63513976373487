var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{ref:"observer",staticClass:"form-guest",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.validateForm)}}},[_c('ValidationProvider',{staticClass:"form-guest__group",attrs:{"rules":"required|email","name":"Email","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('float-label',[_c('input',{attrs:{"type":"text","name":"email","placeholder":"Email"},domProps:{"value":_vm.value.email},on:{"input":function($event){return _vm.update('email', $event.target.value)}}})]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(errors),expression:"errors"}],staticClass:"form-guest__errors"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{staticClass:"form-guest__group",attrs:{"rules":"required|min:2|alpha_dash","name":"First Name","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('float-label',[_c('input',{attrs:{"type":"text","name":"first_name","placeholder":"Firstname"},domProps:{"value":_vm.value.first_name},on:{"input":function($event){return _vm.update('first_name', $event.target.value)}}})]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(errors),expression:"errors"}],staticClass:"form-guest__errors"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{staticClass:"form-guest__group",attrs:{"rules":"required|min:2|alpha_dash","name":"Last Name","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('float-label',[_c('input',{attrs:{"type":"text","name":"last_name","placeholder":"Surname"},domProps:{"value":_vm.value.last_name},on:{"input":function($event){return _vm.update('last_name', $event.target.value)}}})]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(errors),expression:"errors"}],staticClass:"form-guest__errors"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('ValidationProvider',{staticClass:"form-guest__group",attrs:{"rules":"required|min:7","name":"Password","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('float-label',[_c('input',{attrs:{"type":"password","name":"password","placeholder":"Password","id":"password-register"},domProps:{"value":_vm.value.password},on:{"input":function($event){return _vm.update('password', $event.target.value)}}}),_c('fa',{staticClass:"form-guest__password-icon",attrs:{"icon":_vm.registerPasswordIcon},on:{"click":function($event){return _vm.togglePasswordVisibility('register')}}})],1),_c('span',{directives:[{name:"show",rawName:"v-show",value:(errors),expression:"errors"}],staticClass:"form-guest__errors"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('div',{staticClass:"form-guest__footer"},[_c('ValidationProvider',{staticClass:"form-guest__checkbox",attrs:{"rules":{ required: { allowFalse: false } },"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{staticClass:"checkbox-v2 em2",attrs:{"id":"privacy","name":"privacy","type":"checkbox"},domProps:{"value":_vm.value.privacy},on:{"change":function($event){return _vm.update('privacy', $event.target.checked)}}}),_c('label',{staticClass:"form-guest__label form-guest__label--small",class:{'form-guest__label--invalid': errors[0]},attrs:{"for":"privacy"}},[_c('span',[_vm._v("I agree to the "),_c('a',{attrs:{"href":"https://www.fonetti.com/terms-of-use/","target":"_blank"}},[_vm._v("Terms & Conditions")]),_vm._v(" and have read the "),_c('a',{attrs:{"href":"https://www.fonetti.com/privacy-policy/","target":"_blank"}},[_vm._v("Privacy Policy")])])])]}}],null,true)}),_c('input',{staticClass:"checkbox-v2 em2",attrs:{"id":"receive_marketing_emails","name":"receive_marketing_emails","type":"checkbox"},domProps:{"value":_vm.value.receive_marketing_emails},on:{"input":function($event){return _vm.update('receive_marketing_emails', $event.target.checked)}}}),_c('label',{staticClass:"form-guest__label form-guest__label--small",attrs:{"for":"receive_marketing_emails"}},[_vm._v("I want to be kept up to date with new features and special promotions")])],1),_c('v-button',{staticClass:"btn btn-primary mt-40",attrs:{"group":"auth"}},[_vm._v("create my account")])],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }