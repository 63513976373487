<template>
  <section class="reset-password-page">
    <div class="row">
      <div class="col">

        <h2 class="page-title">Reset your password</h2>
        <flash-message></flash-message>
        <template v-if="!resetSuccessful">
          <h3 v-if="formEnabled">Choose a new password</h3>
          <reset-password-form v-if="formEnabled" v-model="form" @validated="resetPassword"></reset-password-form>
        </template>
        <template v-if="resetSuccessful">
          <p>Great, your password has been reset successfully. You can now login using your new password and the email address you registered with us.</p>
          <button class="btn btn-primary" @click="toNextUrl()">Go to login</button>
        </template>
      </div>
    </div>
  </section>
</template>

<script>
import _ from 'lodash';
import ResetPasswordForm from '@/components/forms/ResetPasswordForm';

export default {
  name: 'reset-password',
  components: {
    ResetPasswordForm,
  },
  data() {
    return {
      form: {
        password: '',
      },
      formEnabled: false,
      resetSuccessful: false,
    };
  },
  mounted() {
    this.$store.commit('SET_LAYOUT', 'guest-layout');
    const { token } = this.$route.query;
    if (_.isEmpty(token)) {
      this.$router.replace('/');
    }
    this.token = token;
    this.validateToken(token);
  },
  methods: {
    validateToken(token) {
      this.$api.post('users/password/verify', { token })
        .then(() => {
          this.formEnabled = true;
        })
        .catch(() => {
          this.flashMessage('Sorry, the token has expired. Click <a href="/password/forgotten">here</a> to try again.', 'error');
          this.formEnabled = false;
        });
    },
    resetPassword() {
      this.buttonGroupIsLoading('password', true);
      this.$api.post('users/password/update', { token: this.token, password: this.form.password })
        .then(() => {
          this.formEnabled = false;
          this.resetSuccessful = true;
        })
        .catch((error) => {
          this.flashMessage(error, 'error');
        })
        .finally(() => this.buttonGroupIsLoading('password', false));
    },
    toNextUrl() {
      this.$router.push('/');
    },
  },
};
</script>
