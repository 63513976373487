import _ from 'lodash';

export default {
  methods: {
    getCustomer() {
      return new Promise((resolve, reject) => {
        this.$api.get('users/me/stripe/customer')
          .then((response) => {
            const { customer } = response.data;
            resolve(customer);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    createCustomer() {
      return new Promise((resolve, reject) => {
        this.$api.post('users/me/stripe/customer')
          .then((response) => {
            const { customer } = response.data;
            resolve(customer);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getInvoices() {
      return new Promise((resolve, reject) => {
        this.$api.get('users/me/stripe/invoices')
          .then((response) => {
            const { invoices } = response.data;
            resolve(invoices);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    addPaymentCard(data) {
      return new Promise((resolve, reject) => {
        this.$api.post('/subscriptions/stripe/sources', data)
          .then(() => {
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
  computed: {
    defaultCardId() {
      let card = _.find(this.customer.cards, (c) => c.default);
      if (card === undefined) {
        card = _.head(this.customer.cards);
      }
      return card ? card.id : null;
    },
    hasSubscription() {
      if (this.$store.state.user) {
        const userSubscriptions = this.$store.state.user.subscriptions;
        let userHasSubscription = false;
        for (let i = 0; i < userSubscriptions.length; i++) {
          if (!userSubscriptions[i].has_expired) {
            userHasSubscription = true;
            break;
          }
        }
        return userHasSubscription;
      }
      return false;
    },
  },
};
