<template>
  <ValidationObserver v-slot="{ handleSubmit }" slim>
    <form class="form-promocode" @submit.prevent="handleSubmit(validateForm)">
      <input type="text" name="code" placeholder="Got a promotion code?" :value="value.code"
             @input="update('code', $event.target.value)">
      <v-button class="btn btn-primary" group="promocode">apply</v-button>
    </form>
  </ValidationObserver>
</template>

<script>
import FormMixin from '@/components/mixins/FormMixin';
import Button from '@/components/utils/Button';

export default {
  name: 'promocode-form',
  mixins: [FormMixin],
  components: {
    'v-button': Button,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
};
</script>
