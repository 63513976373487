import axios from 'axios';
import config from '../config';
import store from '../store';
import router from '../router';

axios.defaults.baseURL = config.serverURI;

axios.interceptors.request.use((config) => { // eslint-disable-line no-shadow
  if (store.state.token != null) {
    config.headers['X-Auris-Token'] = store.state.token;
  } else {
    delete config.headers['X-Auris-Token'];
  }
  return config;
});

// eslint-disable-next-line consistent-return
axios.interceptors.response.use((response) => response, (error) => {
  // Provides a mechanism for disabling this interceptor if needed
  const requestConfig = error.response.config;
  if ('autoErrorHandling' in requestConfig) {
    if (requestConfig.autoErrorHandling === false) {
      return Promise.reject(error);
    }
  }

  if (error.response.status === 403 || (error.response.status === 401 && error.response.data.error_type === 'generic' && error.response.data.error === 'Unauthorised')) {
    store.dispatch('logout')
      .then(() => router.push('/'));
  } else {
    return Promise.reject(error);
  }
});

export default axios;
