<template>
  <ValidationObserver v-slot="{ handleSubmit }" slim>
    <form ref="observer" @submit.prevent="handleSubmit(validateForm)" class="form-guest">
      <ValidationProvider rules="required" name="Name" v-slot="{ errors }" tag="div" class="form-guest__group">
        <float-label>
          <input type="text" class="form-input name" name="name" placeholder="Name" :value="value.name"
            @input="update('name', $event.target.value)">
        </float-label>
        <span v-show="errors" class="form-guest__errors">{{ errors[0] }}</span>
      </ValidationProvider>
      <ValidationProvider rules="required" name="Age" v-slot="{ errors }" tag="div" class="form-guest__group">
        <float-label>
          <input type="number" class="form-input age" name="age" placeholder="Age" :value="value.age" @input="update('age', $event.target.value)">
        </float-label>
        <span v-show="errors" class="form-guest__errors">{{ errors[0] }}</span>
      </ValidationProvider>
      <div class="button-container">
        <v-button class="btn btn-primary submit" type="submit" group="readers">{{ submitText }}</v-button>
        <v-button v-if="showDelete" class="btn btn-secondary submit" type="button"
          group="readersDelete" @click="$emit('deleteReader')">Delete Reader</v-button>
        <router-link class="btn btn-secondary cancel" to="/my/dashboard">Cancel</router-link>
      </div>
    </form>
  </ValidationObserver>
</template>
<script>
import FormMixin from '@/components/mixins/FormMixin';
import Button from '@/components/utils/Button';

export default {
  name: 'reader-form',
  mixins: [FormMixin],
  components: {
    'v-button': Button,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    submitText: {
      type: String,
      default: 'Continue',
    },
    showDelete: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style lang="scss" scoped>
  .form-guest {
    input {
      &.name {
        width: 100%;
        @media (min-width: 992px) {
          width: 50%;
        }
      }
      &.age {
        width: 75%;
        @media (min-width: 992px) {
          width: 25%;
        }
      }
    }
    .button-container {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      @media (min-width: 992px) {
        justify-content: start;
        flex-wrap: nowrap;
        .submit, .cancel {
          width: min-content !important;
        }
      }
      .submit {
        width: 100%;
        white-space: nowrap;
        margin: .3rem .3rem 0 0;
      }
      .cancel {
        width: 100%;
        display: block;
        font-size: 1rem;
        border-radius: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: .3rem .3rem 0 0;
        height: 50px;
        @media (min-width: 992px) {
          height: 40px;
        }
      }
    }
  }
</style>
