<template>
  <div class="subscription-box">
    <div class="subscription-box__cover">
      <img :src="coverImage" :alt="subscription.title">
    </div>
    <div class="subscription-box__wrapper">
      <h3>{{ subscription.title }}</h3>
      <div class="subscription-box__content">
        <div class="subscription-box__description">{{ subscription.description }}</div>
        <div class="subscription-box__footer">
          <button class="btn btn-primary" @click="manage">{{ buttonText }}</button>
          <component :is="promoComponent" :duration-length="applicablePromocode.duration_length" :discount-value="applicablePromocode.discount_value" v-if="applicablePromocode !== null && isDiscounted"></component>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import slug from 'slug';
import _ from 'lodash';
import FreeTrial from '@/components/ui/FreeTrial';
import Coupon from '@/components/ui/Coupon';

export default {
  name: 'subscription-box',
  props: {
    isCurrent: {
      required: false,
      default: false,
      type: Boolean,
    },
    subscription: {
      required: true,
      type: Object,
    },
  },
  components: {
    FreeTrial,
    Coupon,
  },
  methods: {
    manage() {
      let url = slug(this.subscription.title);
      url += `-${this.subscription.id}`;
      this.$router.push(`/my/subscriptions/${url}`);
    },
  },
  computed: {
    coverImage() {
      return !_.isEmpty(this.subscription.cover_image) ? this.subscription.cover_image : '/img/missing_subscription_cover.png';
    },
    buttonText() {
      return this.isCurrent ? 'manage' : 'view options';
    },
    promoComponent() {
      if (this.applicablePromocode.type === 'free_trial') {
        return 'free-trial';
      }
      return 'coupon';
    },
    isDiscounted() {
      return this.applicablePromocode !== null;
    },
    applicablePromocode() {
      if (this.subscription.is_awaiting_renewal) {
        return null;
      }
      // do we have the same promocode for each variant/price of the package?
      // if not, we do not show anything about the promocode
      let promocode = null;
      let displayPromocode = true;
      _.forEach(this.subscription.variants, (variant) => {
        _.forEach(variant.prices, (price) => {
          if (price.promocode_data.is_discountable === true) {
            if (promocode !== null && promocode.id !== price.promocode_data.applicable_promocode.id) {
              displayPromocode = false;
            }
            promocode = price.promocode_data.applicable_promocode;
          } else {
            displayPromocode = false;
          }
        });
      });
      return displayPromocode ? promocode : null;
    },
  },
};
</script>
