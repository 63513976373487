<template>
  <button class="product-badge" @click="$emit('click')">
    <div class="product-badge__description">
      <span class="product-badge__amount">{{ buttonText }} £{{ currentPrice }}</span>
      <span class="product-badge__promo" v-if="isDiscounted">was £{{ oldPrice }}</span>
    </div>
  </button>
</template>

<script>
import { mapState } from 'vuex';
import HelpersMixin from '@/components/mixins/HelpersMixin';

export default {
  name: 'product-badge',
  mixins: [HelpersMixin],
  props: {
    product: {
      required: true,
      type: Object,
    },
    buttonText: {
      required: true,
      type: String,
    },
  },
  computed: {
    ...mapState(['user']),
    currentPrice() {
      const purchaseData = this.product.purchase_data;
      if (purchaseData.discounted_price !== null) {
        return purchaseData.discounted_price;
      }
      return purchaseData.price;
    },
    isDiscounted() {
      const purchaseData = this.product.purchase_data;
      return purchaseData.discounted_price !== null;
    },
    oldPrice() {
      return this.product.purchase_data.price;
    },
  },
};
</script>
