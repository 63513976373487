import _ from 'lodash';
import config from '@/config';

export default {
  data() {
    return {
      from: null,
    };
  },
  mounted() {
    this.from = this.$route.query.from;
  },
  methods: {
    login(data, nextRoute) {
      this.buttonGroupIsLoading('auth', true);
      this.$api.post('auth', data)
        .then((response) => {
          const { user, meta } = response.data;
          if (user) {
            if (user.user_type !== 'standard') {
              // eslint-disable-next-line prefer-promise-reject-errors
              const error = {
                response: {
                  data: {
                    error: `Sorry, your account must be administered from our <a href="${config.webPortalURL}">school control panel</a>`,
                  },
                },
              };
              return Promise.reject(error);
            }
            const { token } = meta;
            this.$store.commit('SET_TOKEN', token);
            return this.$api.get('users/me?include=subscriptions');
          }
          return this.$router.replace('/logout');
        })
        .then((response) => {
          const { user } = response.data;
          if (user) {
            this.$store.commit('SET_USER', user);
            return this.redirectAfterAuth(nextRoute);
          }

          return this.logout();
        })
        .catch((error) => {
          let message = null;
          const err = _.get(error, 'response.data.error', null);
          if (err === 'Login information not recognised') {
            message = 'Sorry, we couldn\'t find an account matching those details';
          }
          this.flashMessage(message ?? error, 'error', 'login');
        })
        .finally(() => {
          this.buttonGroupIsLoading('auth', false);
        });
    },
    register(data, nextRoute) {
      this.buttonGroupIsLoading('auth', true);
      this.$api.post('users', data)
        .then((response) => {
          const { user, meta } = response.data;
          if (user) {
            const { token } = meta;
            this.$store.commit('SET_TOKEN', token);
            return this.$api.get('users/me');
          }

          return this.logout();
        })
        .then((response) => {
          if (response.data.user) {
            const { user } = response.data;
            this.$store.commit('SET_USER', user);
            return this.redirectAfterAuth(nextRoute);
          }

          return this.logout();
        })
        .catch((error) => {
          this.flashMessage(error, 'error', 'register');
        })
        .finally(() => {
          this.buttonGroupIsLoading('auth', false);
        });
    },
    logout() {
      this.$store.dispatch('logout')
        .then(() => {
          this.$router.push('/');
        });
    },
    redirectAfterAuth(nextRoute) {
      const query = { ...this.$route.query };
      delete query.from;
      if (this.from !== null && this.from !== undefined) {
        return this.$router.push({ name: this.from, query });
      }

      return this.$router.push(nextRoute);
    },
  },
};
