<template>
  <div class="coupon">
    <fa-layers>
      <fa icon="certificate" class="coupon__icon"></fa>
      <fa-layers-text :value="value" class="coupon__value" transform="right-14"></fa-layers-text>
    </fa-layers>
  </div>
</template>

<script>
export default {
  name: 'coupon',
  props: {
    discountValue: {
      required: true,
    },
  },
  computed: {
    value() {
      const number = parseFloat(this.discountValue);
      return `${+(number.toFixed(2))}%`;
    },
  },
};
</script>
