<template>
  <div class="book-stats-wrapper">
    <div class="book-wrapper d-none d-lg-block">
      <img class="book-cover" :src="book.book_cover_image" loading="lazy">
    </div>
    <div class="info-wrapper">
      <h1 class="title"
        v-tooltip="{content: book.book_title}">{{ book.book_title }}</h1>
      <div class="stars-wrapper">
        <div v-html="starsFromNumber(book.total_accuracy)">
        </div>
      </div>
    </div>
    <div class="stat-wrapper">
      <div class="stat accuracy">
        <span class="stat-value">
          {{ Math.floor(book.total_accuracy) }}%
        </span>
        <!-- <span class="stat-label">Accuracy</span> -->
      </div>
      <div class="stat reading-time">
        <span class="stat-value" v-html="book.reading_time">
        </span>
        <!-- <span class="stat-label">Time Reading</span> -->
      </div>
      <div class="stat times-read">
        <span class="stat-value">{{ book.book_readings_count }}</span>
        <!-- <span class="stat-label">Times Read</span> -->
      </div>
    </div>
  </div>
</template>
<script>
import VToolTip from 'v-tooltip';

export default {
  props: ['book'],
  components: {
    VToolTip, // eslint-disable-line
  },
  methods: {
    starsFromNumber(score, base = 3) {
      let response = '';
      let i = 1;
      const perStar = 100 / base;
      while (i <= base) {
        if (score >= perStar * i) {
          response += '<span class="star-rating"><img src="/img/stars/starsGold.png"></span> ';
        } else {
          response += '<span class="star-rating"><img src="/img/stars/starsGrey.png"></span> ';
        }
        i++;
      }
      return response.trim();
    },
  },
};
</script>
<style lang="scss">
$primary: #ffce4e;

.book-stats-wrapper {
  height: 200px;
  display: flex;
  justify-content: start;
  flex-direction: column;
  padding: 0.5rem;
  background-color: #ffffff20;
  margin-bottom: 10px;
  border-radius: 15px;
  width: 100%;
  @media (min-width: 992px) {
    min-width: 100%;
    width: min-content;
    flex-direction: row;
  }

  .book-wrapper {
    display: flex;
    height: 100%;
    width: fit-content;
    align-items: center;
    margin-left: calc(20px - 0.5rem);

    .book-cover {
      margin-right: 15px;
      height: 170px;
      width: 170px;
      border-radius: 10px;
    }
  }

  .info-wrapper {
    flex-grow: 1;
    flex-basis: 20%;
    display: flex;
    justify-content: start;
    align-items: center;
    @media (min-width: 992px) {
      padding-top: 2em;
      display: block;
    }

    .title {
      color: white;
      // border-bottom: 1px solid $primary;
      text-transform: uppercase;
      text-align: center;
      font-weight: 700;
      font-size: 1em;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2; /* number of lines to show */
      line-clamp: 2;
      -webkit-box-orient: vertical;
      height: min-content;
      max-height: 70px;
      margin-bottom: 0;
      line-height: 1.2;
      &:hover {
        cursor: default;
      }
      width: 90%;
      @media (min-width: 992px) {
        width: 100%;
        padding-bottom: 10px;
        margin-bottom: 0.5rem;
        font-size: 1.2em;
      }
    }
    .stars-wrapper {
      display: flex;
      justify-content: center;
      & > div {
        display: flex;
        justify-content: center;
        width: min-content;
        @media (min-width: 992px) {
          width: 160px;
          display: block;
        }
        .star-rating {
          img {
            width: 25px;
            @media (min-width: 992px) {
              width: 50px;
            }
          }
        }
      }
    }
  }

  .stat-wrapper {
    display: flex;
    justify-content: space-around;
    height: 100%;

    .stat {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: end;
      width: 120px;
      background-size: contain !important;
      background-repeat: no-repeat !important;
      background-position: center;
      &.accuracy {
        background-image: url('~@/assets/img/progress/progress__accuracy.png');
      }
      &.reading-time {
        background-image: url('~@/assets/img/progress/progress__time_reading.png');
        .stat-value {
          font-size: 20px;
          @media (min-width: 992px) {
            font-size: 25px;
          }
          line-height: 1;
        }
      }
      &.times-read {
        background-image: url('~@/assets/img/progress/progress__times_read.png');
      }
      &:not(:last-child) {
        margin-right: 1em;
      }

      .stat-value {
        flex-grow: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        font-size: 25px;
        @media (min-width: 992px) {
          font-size: 30px;
        }
        font-weight: bold;
        margin-bottom: 15px;
      }

      .stat-label {
        font-size: 25px;
      }
    }
  }
}
</style>
