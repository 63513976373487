<template>
  <header class="header-mobile">
    <button ref="hamburger" class="hamburger hamburger--squeeze" type="button" @click="triggerHamburger">
      <span class="hamburger-box">
        <span class="hamburger-inner"></span>
      </span>
    </button>
    <sidebar-mobile @click="triggerHamburger"></sidebar-mobile>
    <h2 class="section-title">{{ $route.name }}</h2>
    <img src="../assets/img/fonetti.png" class="header-mobile__logo" alt="Fonetti">
  </header>
</template>

<script>
import SidebarMobile from '@/components/SidebarMobile';

export default {
  name: 'header-mobile',
  components: {
    SidebarMobile,
  },
  data() {
    return {
      title: null,
    };
  },
  methods: {
    triggerHamburger() {
      if (this.$refs.hamburger.classList.contains('is-active')) {
        this.$refs.hamburger.classList.remove('is-active');
      } else {
        this.$refs.hamburger.classList.add('is-active');
      }
      const menuMobile = document.getElementById('menu-mobile');
      const body = document.getElementsByTagName('body')[0];
      const menuMobileWrapper = document.getElementById('menu-mobile-wrapper');
      if (this.$refs.hamburger.classList.contains('is-active')) {
        menuMobile.classList.add('is-open');
        body.classList.add('menu-open');
        menuMobileWrapper.classList.add('is-open');
      } else {
        menuMobile.classList.remove('is-open');
        body.classList.remove('menu-open');
        menuMobileWrapper.classList.remove('is-open');
      }
    },
  },
};
</script>
