<template>
  <section></section>
</template>

<script>
import AuthMixin from '@/components/mixins/AuthMixin';

export default {
  name: 'logout',
  mixins: [AuthMixin],
  mounted() {
    this.logout();
  },
};
</script>
