import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

// eslint-disable-next-line
const getDefaultState = () => {
  return {
    layout: null,
    flashMessage: null,
    token: null,
    user: null,
    config: null,
    loadingButtonGroup: {},
    onMobile: true,
    queryCode: null,
    previewPromocode: null,
    showLayout: false,
    hasSubscription: false,
    stripe: null,
    readerSummary: {},
  };
};

export default new Vuex.Store({
  state: getDefaultState(),
  mutations: {
    SET_LAYOUT(state, payload) {
      state.layout = payload;
    },
    SHOW_LAYOUT(state, payload) {
      state.showLayout = payload;
    },
    SET_TOKEN(state, payload) {
      state.token = payload;
      if (window.localStorage) {
        window.localStorage.setItem('token', payload);
      }
    },
    SET_USER(state, payload) {
      state.user = payload;
      if (window.localStorage) {
        window.localStorage.setItem('user', JSON.stringify(payload));
      }
      if (payload.subscriptions) {
        state.hasSubscription = payload.subscriptions
          .filter((subscription) => !subscription.has_expired && (subscription.renewal_status === 'active' || subscription.payment_provider === 'manual'))
          .length > 0;
      }
      return true;
    },
    SET_FLASH_MESSAGE(state, payload) {
      state.flashMessage = payload;
    },
    SET_CONFIG(state, payload) {
      state.config = payload;
    },
    SET_LOADING_BUTTON_GROUP(state, payload) {
      // eslint-disable-next-line
      state.loadingButtonGroup = Object.assign({}, state.loadingButtonGroup, payload);
    },
    SET_ALL_LOADING_BUTTON_GROUP(state, payload) {
      Object.keys(state.loadingButtonGroup)
        .sort()
        // eslint-disable-next-line array-callback-return
        .map((key) => {
          state.loadingButtonGroup[key] = payload;
        });
    },
    IS_ON_MOBILE(state, payload) {
      state.onMobile = payload;
    },
    SET_QUERY_CODE(state, payload) {
      state.queryCode = payload;
    },
    SET_PREVIEW_PROMOCODE(state, payload) {
      state.previewPromocode = payload;
    },
    SET_STRIPE(state, payload) {
      state.stripe = payload;
    },
    RESET_PROMOCODE(state) {
      state.previewPromocode = null;
      state.queryCode = null;
    },
    HAS_SUBSCRIPTION(state, payload) {
      state.hasSubscription = payload;
    },
    LOGOUT(state) {
      state.token = null;
      state.user = null;
      const onMobileOld = state.onMobile;
      Object.assign(state, getDefaultState());
      state.onMobile = onMobileOld;
      state.showLayout = true;
      if (window.localStorage) {
        window.localStorage.removeItem('token');
        window.localStorage.removeItem('user');
      }
    },
    SET_READER_SUMMARY(state, payload) {
      const { id, progress } = payload;
      state.readerSummary[id] = progress;
    },
  },
  actions: {
    logout({ commit }) {
      return new Promise((resolve) => {
        commit('LOGOUT');
        resolve();
      });
    },
  },
  getters: {
    layout(state) {
      return state.layout;
    },
    isLoggedIn: (state) => !!state.token,
    isConfigLoaded: (state) => state.config !== null && state.stripe !== null,
    showMobile: (state) => state.showLayout && state.onMobile,
    showDesktop: (state) => state.showLayout && !state.onMobile,
    userReadersCount: (state) => (state.user ? state.user.readers.length : 0),
  },
});
