<template>
    <section class="dashboard-page">
        <div class="header">
          <h2 class="title">Dashboard</h2>
          <router-link :to="'/my/readers/add'" class="btn btn-primary">Add Profile</router-link>
        </div>
        <div class="reader-card-wrapper">
            <div class="row g-2">
                <div class="col-12 col-md-6 col-xl-4" v-for="(reader, index) in readers" :key="index">
                    <ReaderCard :reader="reader"></ReaderCard>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import ReaderCard from '@/components/readers/ReaderCard';
import Reader from '@/components/classes/Reader';

export default {
  components: {
    ReaderCard,
  },
  data() {
    return {
      readers: [],
    };
  },
  mounted() {
    if (this.$store.state.user.readers.length) {
      const { readers } = this.$store.state.user;
      this.readers = readers.map((reader) => new Reader(reader));
    }
  },
};
</script>
<style lang="scss">
  .dashboard-page {
    .header {
      display: flex;
      justify-content: space-between;
      width: 100%;
      border-bottom: 1px solid #ffce4e;
      .title {
        color: #ffce4e;
        text-transform: uppercase;
        padding-bottom: 10px;
        font-weight: 700;
        font-size: 1.2em;
      }
    }
    a.btn:hover {
      color: white;
    }
  }
</style>
