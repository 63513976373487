<template>
  <button :disabled="disabled" @click="$emit('click')">
    <span v-if="loading" class="loading-indicator"><fa icon="spinner" spin></fa></span>
    <slot></slot>
  </button>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'v-button',
  props: {
    group: { required: false, type: String },
  },
  computed: {
    ...mapState(['loadingButtonGroup']),
    disabled() {
      if (this.loadingButtonGroup[this.group] === true || this.loadingButtonGroup.all === true) {
        return true;
      }
      return false;
    },
    loading() {
      if (this.loadingButtonGroup[this.group] === true || this.loadingButtonGroup.all === true) {
        return true;
      }
      return false;
    },
  },
};
</script>
