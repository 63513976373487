import Vue from 'vue';
import App from '@/App';
import router from '@/router';
import store from '@/store';
import api from '@/api';
import config from '@/config';
import FlashMessage from '@/components/utils/FlashMessage';
import FlashMessageMixin from '@/components/mixins/FlashMessageMixin';
import HelpersMixin from '@/components/mixins/HelpersMixin';
import './assets/scss/main.scss';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faStar, faBook, faClock, faExclamationCircle, faEye, faEyeSlash, faCircle, faExclamation, faChild, faCheck, faAward, faCertificate, faEnvelope, faTimesCircle, faSpinner, faLock, faPoundSign,
} from '@fortawesome/free-solid-svg-icons';
import { faFacebookF, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from '@fortawesome/vue-fontawesome';
import moment from 'moment';
import VToolTip from 'v-tooltip';
import {
  ValidationProvider, ValidationObserver, setInteractionMode, extend, configure,
} from 'vee-validate';
import en from 'vee-validate/dist/locale/en.json';
import {
  required, email, min, alpha_dash, is // eslint-disable-line
} from 'vee-validate/dist/rules';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginVue from '@bugsnag/plugin-vue';

Vue.config.productionTip = false;

Vue.use(VToolTip);

Vue.component('flash-message', FlashMessage);
Vue.mixin(FlashMessageMixin);
Vue.mixin(HelpersMixin);
Vue.prototype.$api = api;
Vue.prototype.$bus = new Vue();

library.add(faStar, faBook, faClock, faChild, faCheck, faExclamationCircle, faEye, faEyeSlash, faCircle, faExclamation, faAward, faCertificate, faEnvelope, faFacebookF, faTwitter, faTimesCircle, faSpinner, faLock, faPoundSign);
Vue.component('fa', FontAwesomeIcon);
Vue.component('fa-layers', FontAwesomeLayers);
Vue.component('fa-layers-text', FontAwesomeLayersText);

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
setInteractionMode('eager');
extend('required', {
  ...required,
  message: en.messages.required,
});
extend('email', {
  ...email,
  message: en.messages.email,
});
extend('min', {
  ...min,
  message: en.messages.min,
});
extend('alpha_dash', {
  ...alpha_dash, // eslint-disable-line
  message: en.messages.alpha_dash,
});
extend('is', {
  ...is,
  message: 'The {_field_} field must match the registered email',
});

configure({
  classes: {
    valid: 'is-valid',
    invalid: 'is-invalid',
  },
});

Vue.filter('moment', (date) => {
  if (!date) return '';
  return moment.unix(date).format('Do MMMM YYYY');
});

// BugSnag config
Bugsnag.start({
  apiKey: '97a0d8d66a855be72f276b3555d3f100',
  plugins: [new BugsnagPluginVue()],
  releaseStage: config.releaseStage,
  enabledReleaseStages: ['production', 'staging', 'development'],
});

const bugsnagVue = Bugsnag.getPlugin('vue');
bugsnagVue.installVueErrorHandler(Vue);

router.beforeEach((to, from, next) => {
  if (to.query.promo) {
    store.commit('SET_QUERY_CODE', to.query.promo);
  }
  next();
});

function getLocalStorageUser() {
  try {
    const localUserString = window.localStorage.getItem('user');
    const localUser = JSON.parse(localUserString);
    // if (!(localUser instanceof Object) || !('id' in localUser) || !('email' in localUser) || !('subscriptions' in localUser) || !Array.isArray(localUser.subscriptions)) {
    if (!(localUser instanceof Object) || !('id' in localUser) || !('email' in localUser)) {
      throw new Error('Bad local user data');
    }
    return localUser;
  } catch {
    window.localStorage.removeItem('token');
    window.localStorage.removeItem('user');
    return null;
  }
}

// Check local storage to handle refreshes
if (window.localStorage) {
  const localUser = getLocalStorageUser();
  if (localUser && store.state.user !== localUser) {
    store.commit('SET_USER', localUser);
    store.commit('SET_TOKEN', window.localStorage.getItem('token'));
  }
}

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
