<template>
  <ValidationObserver v-slot="{ handleSubmit }" slim>
    <form ref="observer" @submit.prevent="handleSubmit(validateForm)" class="form-guest form-account">
      <ValidationProvider rules="required|email" name="Email" v-slot="{ errors }" tag="div" class="form-guest__group">
        <float-label>
          <input type="text" name="email" placeholder="Email" :value="value.email" @input="update('email', $event.target.value)">
        </float-label>
        <span v-show="errors" class="form-guest__errors">{{ errors[0] }}</span>
      </ValidationProvider>
      <div class="row">
        <div class="col-md-6">
          <ValidationProvider rules="required|min:2|alpha_dash" name="First Name" v-slot="{ errors }" tag="div" class="form-guest__group">
            <float-label>
              <input type="text" name="first_name" placeholder="Firstname" :value="value.first_name" @input="update('first_name', $event.target.value)">
            </float-label>
            <span v-show="errors" class="form-guest__errors">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>
        <div class="col-md-6">
          <ValidationProvider rules="required|min:2|alpha_dash" name="Last Name" v-slot="{ errors }" tag="div" class="form-guest__group">
            <float-label>
              <input type="text" name="last_name" placeholder="Surname" :value="value.last_name" @input="update('last_name', $event.target.value)">
            </float-label>
            <span v-show="errors" class="form-guest__errors">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <a @click="displayPasswordInput" class="btn btn-secondary form-account__button">Change password</a>
        </div>
      </div>
      <ValidationProvider rules="required|min:7" name="Password" v-slot="{ errors }" tag="div" class="form-guest__group" v-if="showPasswordField">
        <float-label>
          <input type="password" name="password" placeholder="Password" :value="value.password" @input="update('password', $event.target.value)" id="password-register">
          <fa :icon="registerPasswordIcon" @click="togglePasswordVisibility('register')" class="form-guest__password-icon"></fa>
        </float-label>
        <span v-show="errors" class="form-guest__errors">{{ errors[0] }}</span>
      </ValidationProvider>
      <div class="form-guest__group">
        <input id="receive_marketing_emails" name="receive_marketing_emails" type="checkbox" class="checkbox-v2 em2" :value="value.receive_marketing_emails" :checked="value.receive_marketing_emails" @input="update('receive_marketing_emails', $event.target.checked)">
        <label for="receive_marketing_emails" class="form-guest__label form-guest__label--small">I want to be kept up to date with new features and special promotions</label>
      </div>
       <div class="form-guest__group">
        <input id="weekly_digest_optin" name="weekly_digest_optin" type="checkbox" class="checkbox-v2 em2" :value="value.weekly_digest_optin" :checked="value.weekly_digest_optin" @input="update('weekly_digest_optin', $event.target.checked)">
        <label for="weekly_digest_optin" class="form-guest__label form-guest__label--small">I want to receive a weekly digest of reading activity</label>
      </div>
      <div class="button-locator">
        <v-button class="btn btn-primary mt-40" group="account">update my details</v-button>
        <a @click="$emit('delete-account')" class="btn btn-secondary form-account__button">delete my account</a>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import FormMixin from '@/components/mixins/FormMixin';
import Button from '@/components/utils/Button';

export default {
  name: 'account-form',
  mixins: [FormMixin],
  components: {
    'v-button': Button,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showPasswordField: false,
    };
  },
  methods: {
    displayPasswordInput() {
      this.showPasswordField = !this.showPasswordField;
      this.update('update_password', this.showPasswordField);
    },
  },
};
</script>

<style lang="scss" scoped>
  .form-account {
    margin-top: 50px;
    &__button {
      display: inline-block;
      margin-bottom: 30px;
    }
  }

  .button-locator {
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    a {
      width: 70%;
      height: 40px;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
</style>
