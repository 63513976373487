<template>
  <div class="flash-message" :class="{ 'flash-message__error': type === 'error', 'flash-message__success': type === 'success' }" v-if="message" v-html="message">
  </div>
</template>

<script>
export default {
  name: 'flash-message',
  props: {
    target: {
      required: false,
      default: 'global',
    },
  },
  data() {
    return {
      message: null,
      type: null,
    };
  },
  mounted() {
    this.$bus.$on('flash-message', ({ type, message, target }) => {
      if (target === this.target) {
        this.message = message;
        this.type = type;
      } else {
        this.message = null;
        this.type = null;
      }
    });
  },
};
</script>
