<template>
  <div class="free-trial">
    <fa-layers class="free-trial__layers" :class="'free-trial__icon--' + localDurationLength">
      <fa v-for="i in localDurationLength"
          icon="star"
          class="free-trial__icon"
          :class="'zindex-' + (i-1)"
          :transform="'left-' + 8 * (i-1)"
          :key="i"></fa>
    </fa-layers>
    <span>first {{ duration }} free</span>
  </div>
</template>

<script>
import pluralize from 'pluralize';

export default {
  name: 'free-trial',
  props: {
    durationLength: {
      type: Number,
      required: true,
    },
    durationUnit: {
      type: String,
      required: false,
      default: 'months',
    },
  },
  computed: {
    duration() {
      if (this.durationLength === 1) {
        return pluralize(this.durationUnit, 1);
      }

      return pluralize(this.durationUnit, this.durationLength, true);
    },
    localDurationLength() {
      return Math.min(this.durationLength, 3);
    },
  },
};
</script>
