import _ from 'lodash';

export default {
  methods: {
    flashMessage(message, type, target = 'global') {
      const extractedMessage = this.extractMessage(message, type);
      this.$bus.$emit('flash-message', { type, message: extractedMessage, target });
    },
    extractMessage(message, type) {
      switch (type) {
        case 'error':
          if (!_.has(message, 'response')) { // not coming from the API
            return message;
          }
          if (message.response.data.error_type === 'validation') {
            let errors = '<ul>';
            _.map(message.response.data.errors, (err) => {
              errors += `<li>${err}</li>`;
            });
            errors += '</ul>';
            return errors;
          }
          if (message.response.data.error) {
            return message.response.data.error;
          }
          return message;
        case 'success':
          return _.get(message, 'data.message', message);
        default:
          return message;
      }
    },
  },
};
