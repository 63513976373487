<template>
  <section class="home-page">
    <div class="row">
      <flash-message></flash-message>
    </div>
    <div class="sm-none">
      <div class="row mb-4">
        <div class="col-md-12">
          <h2 class="page-title">get started</h2>
          <div class="promocode__summary" v-if="hasPromoCode && promocode !== null">
            <p v-html="promocodePrettyDescription"></p>
          </div>
          <p class="text-center mt-10 promocode__guidance" v-if="hasPromoCode && promocode !== null">Please sign in or create an account to continue.</p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 divider__vertical--right">
          <div class="column--left">
            <h3>Set up my account</h3>
            <register-form v-model="registerForm" @validated="register(registerForm, { name: 'subscriptions' })"></register-form>
            <flash-message target="register"></flash-message>
          </div>
        </div>
        <div class="col-md-6">
          <div class="column--right">
            <h3>I already have an account</h3>
            <login-form v-model="loginForm" @validated="login(loginForm, { name: 'subscriptions'})"></login-form>
            <flash-message target="login"></flash-message>
            <p class="center-text mt-40"><router-link to="/password/forgotten" tag="a" class="home-page__link home-page__link--small">Forgot your password?</router-link></p>
          </div>
        </div>
      </div>
      <div class="footer__text mt-5">
        <span>Have you already downloaded Fonetti?</span><br>If you registered from within the app, sign in using the same email and password to ensure we match up your account.
      </div>
    </div>
    <!--MOBILE ONLY-->
    <div class="sm-only" v-if="onMobile">
      <div class="row">
        <div class="col-md-12">
          <h2 class="page-title">get started</h2>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12" v-if="displayRegister">
          <h3>Already have an account?</h3>
          <button class="btn btn-primary form-guest__button" @click="displayLogin = true; displayRegister = false">go to login</button>
        </div>
        <div class="col-md-12" v-if="displayRegister">
          <div class="column--left mt-40">
            <h3>Set up my account</h3>
            <register-form v-model="registerForm" @validated="register(registerForm, { name: 'subscriptions' })"></register-form>
          </div>
        </div>
        <div class="col-md-12">
          <div class="column--right" v-if="displayLogin">
            <h3>I already have an account</h3>
            <login-form v-model="loginForm" @validated="login(loginForm, { name: 'subscriptions' })"></login-form>
            <p class="center-text mt-40"><router-link to="/" tag="a" class="home-page__link home-page__link--small">Forgot your password?</router-link></p>
          </div>
        </div>
        <div class="col-md-12" v-if="displayLogin">
          <h3>Don't have an account?</h3>
          <button class="btn btn-primary form-guest__button" @click="displayRegister = true; displayLogin = false">set up a new account</button>
        </div>
      </div>
    </div>
  </section>
</template>
<style lang="scss" scoped>
  .promocode__summary {
    p {
      font-size: 1.5em;
    }
  }

  .promocode__guidance {
    font-size: 1.1em;
  }
</style>
<script>
import { mapState } from 'vuex';
import LoginForm from '@/components/forms/LoginForm';
import RegisterForm from '@/components/forms/RegisterForm';
import AuthMixin from '@/components/mixins/AuthMixin';
import PromocodeMixin from '@/components/mixins/PromocodeMixin';

export default {
  name: 'home',
  mixins: [AuthMixin, PromocodeMixin],
  components: {
    LoginForm,
    RegisterForm,
  },
  beforeMount() {
    if (this.hasPromoCode) {
      this.previewPromocode(this.queryCode);
    }
  },
  data() {
    return {
      loginForm: {
        email: '',
        password: '',
      },
      registerForm: {
        email: '',
        first_name: '',
        last_name: '',
        password: '',
        privacy: false,
        receive_marketing_emails: false,
      },
      displayLogin: true,
      displayRegister: true,
    };
  },
  mounted() {
    this.$store.commit('SET_LAYOUT', 'guest-layout');
    this.displayRegister = !this.onMobile;
  },
  computed: {
    ...mapState(['onMobile']),
    hasPromoCode() {
      return !!this.queryCode;
    },
  },
};
</script>
