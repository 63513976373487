<template>
  <section class="forgotten-password-page">
    <div class="row">
      <div class="col">
        <h2 class="page-title">Forgotten password</h2>
        <p class="mb-5">Pop your registered email address into the box below and we'll send you a password reset code to your inbox.</p>
        <forgotten-password-form v-model="form" @validated="sendPasswordResetRequest"></forgotten-password-form>
        <flash-message></flash-message>
      </div>
    </div>
  </section>
</template>

<script>
import ForgottenPasswordForm from '@/components/forms/ForgottenPasswordForm';

export default {
  name: 'forgotten-password',
  components: {
    ForgottenPasswordForm,
  },
  data() {
    return {
      form: {
        email: '',
      },
    };
  },
  mounted() {
    this.$store.commit('SET_LAYOUT', 'guest-layout');
  },
  methods: {
    sendPasswordResetRequest() {
      this.buttonGroupIsLoading('password', true);
      const data = { email: this.form.email, isSchool: false };
      this.$api.post('users/password/reset', data)
        .then(() => {
          this.flashMessage('Please check your inbox for further instructions.', 'success');
        })
        .catch((error) => {
          this.flashMessage(error, 'error');
        })
        .finally(() => {
          this.buttonGroupIsLoading('password', false);
        });
    },
  },
};
</script>
