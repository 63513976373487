<template>
  <ValidationObserver v-slot="{ handleSubmit }">
    <form @submit.prevent="handleSubmit(validateForm)" class="form-guest">
      <ValidationProvider rules="required|email" name="Email" v-slot="{ errors }" tag="div" class="form-guest__group">
        <float-label label="Email Address">
          <input type="email" name="email" id="email" :value="value.email" @input="update('email', $event.target.value)" placeholder="Enter your registered email address">
        </float-label>
        <span v-show="errors" class="form-guest__errors">{{ errors[0] }}</span>
      </ValidationProvider>
      <v-button group="password" class="btn btn-primary">Submit</v-button>
    </form>
  </ValidationObserver>
</template>

<script>
import FormMixin from '@/components/mixins/FormMixin';
import Button from '@/components/utils/Button';

export default {
  name: 'forgotten-password-form',
  mixins: [FormMixin],
  components: {
    'v-button': Button,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
};
</script>
