<template>
  <section class="payments-page">
    <flash-message></flash-message>
    <modal title="New" subtitle="Payment Method" v-if="modal.isVisible('add-payment-card')" @close="modal.hide('add-payment-card')" :show-close-button="true" class="modal__stripe">
      <stripe-card @done="addPaymentCard" :button-text="'submit'" :add-save-as-default-option="true" :check-save-as-default-option="!hasDefaultCard"></stripe-card>
    </modal>
    <h2 class="table-title">Payment Cards <v-button class="btn btn-primary" group="card" @click="modal.show('add-payment-card')">add payment card</v-button></h2>
    <div v-if="cards.length === 0" class="payment-cards-table--empty">
      You currently have no payment cards setup on your account
    </div>
    <table class="table table--bordered table--block payment-cards-table" v-else>
      <tr v-for="(card, index) in cards" :key="index">
        <td class="payment-cards-table__card-icon d-none d-sm-table-cell"><img :src="getBrandIcon(card.brand)" alt="visa"></td>
        <td class="payment-cards-table__card-number">**** **** **** {{ card.last4 }}</td>
        <td class="payment-cards-table__card-status"><span v-if="card.default">default</span></td>
        <td class="payment-cards-table__card-expiry">Expires {{ card.exp_month }}/{{ card.exp_year }}</td>
        <td class="table__cell table__cell-action table__cell">
          <v-button class="btn btn-secondary" group="card" @click="removePaymentCard(card.id)"><span class="d-inline d-sm-none">remove</span><span class="d-none d-sm-inline">remove card</span></v-button>
        </td>
      </tr>
    </table>

    <template v-if="invoices.length > 0">
      <h2 class="table-title mt-40">Payment History</h2>
      <table class="table table--bordered table--block payment-history-table">
        <tr v-for="(invoice, index) in invoices" :key="index">
          <td class="payment-history-table__payment-date">{{ invoice.date | moment }}</td>
          <td class="payment-history-table__payment-amount">£{{ invoice.amount_paid }}</td>
          <td class="payment-history-table__payment-status">{{ invoice.status }}</td>
          <td class="payment-history-table__invoice-number d-none d-sm-table-cell">{{ invoice.number }}</td>
          <td class="table__cell table__cell-action">
            <a class="btn btn-primary" :href="invoice.link">view invoice</a>
          </td>
        </tr>
      </table>
    </template>
  </section>
</template>

<script>
import _ from 'lodash';
import { mapState } from 'vuex';
import CustomerMixin from '@/components/mixins/CustomerMixin';
import Button from '@/components/utils/Button';
import StripeCard from '@/components/StripeCard';
import Modal from '@/components/utils/Modal';
import ModalJS from '@/components/classes/Modal';

export default {
  name: 'payments',
  mixins: [CustomerMixin],
  components: {
    'v-button': Button,
    Modal,
    StripeCard,
  },
  data() {
    return {
      invoices: [],
      cards: [],
      customer: null,
      modal: new ModalJS({
        addPaymentCard: false,
      }),
      hasDefaultCard: false,
    };
  },
  mounted() {
    this.$bus.$emit('set-title', { title: 'Payments' });
    this.$nextTick(() => {
      this.getCustomer()
        .then((customer) => {
          this.customer = customer;
          this.cards = customer.cards;
          this.hasDefaultCard = this.hasDefaultCardFn();
          return this.getInvoices();
        })
        .then((invoices) => { this.invoices = invoices; })
        .catch((error) => {
          if (error.response.status !== 404) {
            this.flashMessage(error, 'error');
          }
        });
    });
  },
  methods: {
    getBrandIcon(brand) {
      let t = 24;
      switch (brand.toLowerCase()) {
        case 'visa':
          t = 1;
          break;
        case 'mastercard':
          t = 2;
          break;
        case 'american express':
          t = 22;
          break;
        case 'discover':
          t = 14;
          break;
        case 'diners club':
          t = 10;
          break;
        case 'jcb':
          t = 16;
          break;
        default:
          t = 24;
          break;
      }
      return `/img/icons/payment-method/${t}.png`;
    },
    refreshCards() {
      this.getCustomer()
        .then((customer) => {
          this.cards = customer.cards;
          this.hasDefaultCard = this.hasDefaultCardFn();
        })
        .catch((error) => {
          this.flashMessage(error, 'error');
        });
    },
    addPaymentCard({ card, name, saveCardAsDefault }) {
      this.$api.post('/users/me/stripe/payment_methods')
        .then((response) => {
          const clientSecret = response.data.client_secret;
          this.stripe.confirmCardSetup(clientSecret, {
            payment_method: {
              card,
              billing_details: { name },
            },
          })
            .then((result) => {
              if (result.error) {
                this.flashMessage(result.error.message, 'error', 'stripe');
              } else {
                if (result.setupIntent.status === 'succeeded') {
                  if (saveCardAsDefault) {
                    this.$api.post(`/users/me/stripe/payment_methods/${result.setupIntent.payment_method}/default`)
                      .then(() => {
                        this.refreshCards();
                      })
                      .catch((error) => {
                        this.flashMessage(error, 'error');
                      });
                  } else {
                    this.refreshCards();
                  }
                  this.modal.hide('add-payment-card');
                } else {
                  this.modal.hide('add-payment-card');
                  this.flashMessage('An error occurred, please contact our team.', 'error');
                }
              }
              this.buttonGroupIsLoading('all', false);
            });
        })
        .catch((error) => {
          this.flashMessage(error, 'error', 'stripe');
          this.buttonGroupIsLoading('all', false);
        });
    },
    removePaymentCard(id) {
      this.buttonGroupIsLoading('card', true);
      this.$api.delete(`/users/me/stripe/payment_methods/${id}`)
        .then(() => {
          this.flashMessage('The payment method has been deleted.', 'success');
          return this.refreshCards();
        })
        .catch((error) => {
          this.flashMessage(error, 'error');
        })
        .finally(() => this.buttonGroupIsLoading('card', false));
    },
    hasDefaultCardFn() {
      const card = _.find(this.cards, (c) => c.default);
      if (card === undefined) {
        return false;
      }
      return true;
    },
  },
  computed: {
    ...mapState(['user', 'stripe']),
  },
};
</script>

<style lang="scss" scoped>
  .payment-cards-table {
    &--empty {
      text-align: center;
      font-style: italic;
      margin-top: 30px;
    }
  }
</style>
