<template>
  <div id="app">
    <div class="sm-only" ref="mobile-only"></div>
    <template v-if="isConfigLoaded">
      <div class="container">
        <component :is="layout"></component>
      </div>
      <footer-mobile v-if="showMobile"></footer-mobile>
    </template>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import config from '@/config';
import GuestLayout from '@/layouts/GuestLayout';
import UserLayout from '@/layouts/UserLayout';
import FooterMobile from '@/components/FooterMobile';
import MobileMixin from '@/components/mixins/MobileMixin';

export default {
  name: 'App',
  mixins: [MobileMixin],
  components: {
    GuestLayout,
    UserLayout,
    FooterMobile,
  },
  mounted() {
    const { layout } = this.$route.meta;
    this.handleLayout(layout);
    this.getConfig();
  },
  methods: {
    getConfig() {
      if (!this.isConfigLoaded) {
        this.$api.get('/app/config')
          .then((response) => {
            const { stripe } = response.data;
            config.stripeApiKey = stripe.public_key;
            this.$store.commit('SET_STRIPE', window.Stripe(config.stripeApiKey));
            this.$store.commit('SET_CONFIG', config);
          }).catch(() => {
            this.flashMessage('An unexpected error occurred.', 'error');
          });
      }
    },
    handleLayout(newLayout) {
      if (newLayout !== this.layout) {
        this.$store.commit('SET_LAYOUT', newLayout);
      }
    },
  },
  computed: {
    layout() {
      return this.$store.getters.layout;
    },
    ...mapGetters(['isConfigLoaded', 'showMobile']),
    ...mapState(['config']),
  },
  watch: {
    config(value) {
      if (value === null) {
        this.getConfig();
      }
    },
    $route(to) {
      const { layout } = to.meta;
      this.handleLayout(layout);
      this.buttonGroupIsLoading('all', false);
    },
  },
};
</script>
