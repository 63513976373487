<template>
  <section class="subscription-page__wrapper" :key="key">
    <flash-message></flash-message>
    <section class="subscription-page" v-if="ready && subscription && !hasManualSubscription">
      <!-- MODALS -->
      <modal title="Are you sure?" v-if="modal.isVisible('unsubscribe')" @close="modal.hide('unsubscribe')" :show-close-button="false" class="modal__warning">
        <p>Your subscription will automatically be canceled on the {{ currentSubscriptionNextRenewalDate }}. Until then you will still be able to access this collection.</p>
        <template v-slot:footer>
          <v-button class="btn btn-primary modal__button" @click="unsubscribe" group="subscribe">Yes, I am sure</v-button>
          <button class="btn btn-cancel modal__button" @click="modal.hide('unsubscribe')">No, keep my subscription active</button>
        </template>
      </modal>
      <modal title="Are you sure?" v-if="modal.isVisible('cancel')" @close="modal.hide('cancel')" :show-close-button="false" class="modal__warning">
        <p>Your subscription will be canceled immediately.</p>
        <template v-slot:footer>
          <v-button class="btn btn-primary modal__button" @click="unsubscribe(true)" group="subscribe">Yes, I am sure</v-button>
          <button class="btn btn-cancel modal__button" @click="modal.hide('cancel')">No, keep my subscription</button>
        </template>
      </modal>
       <modal title="Are you sure?" v-if="modal.isVisible('autorenew')" @close="modal.hide('autorenew')" :show-close-button="false" class="modal__warning">
        <p>Your auto-renewal will be canceled immediately.</p>
        <template v-slot:footer>
          <v-button class="btn btn-primary modal__button" @click="cancelAutoRenew(true)" group="subscribe">Yes, I am sure</v-button>
          <button class="btn btn-cancel modal__button" @click="modal.hide('autorenew')">No, keep my auto-renewal</button>
        </template>
      </modal>
      <modal title="Are you sure?" v-if="modal.isVisible('reactivate')" @close="modal.hide('reactivate')" :show-close-button="false" class="modal__warning">
        <p>Do you really want to reactivate your subscription?</p>
        <template v-slot:footer>
          <v-button class="btn btn-primary modal__button" @click="reactivate" group="subscribe">Yes, I am sure</v-button>
          <button class="btn btn-cancel modal__button" @click="modal.hide('reactivate')">No, keep my subscription inactive</button>
        </template>
      </modal>
      <modal :title="subscribeModalTitle" subtitle="Monthly subscription" v-if="modal.isVisible('stripe')" @close="modal.hide('stripe')" :show-close-button="true" class="modal__stripe">
        <stripe-card :button-text="subscribeModalPrice" @done="subscribeWithNewCard" ></stripe-card>
      </modal>
      <modal :title="subscribeModalTitle" subtitle="Monthly subscription" v-if="modal.isVisible('subscribe')" @close="modal.hide('subscribe')" :show-close-button="true" class="modal__success">
        <div class="form-card">
          <flash-message target="stripe"></flash-message>
          <ValidationProvider rules="required" name="Card" tag="div">
            <label for="card" class="form-card__label">Use existing payment method</label>
            <select name="card" id="card" class="form-card__select" v-model="cardId">
              <option v-for="card in customer.cards" :value="card.id" :key="card.id">**** **** **** {{ card.last4 }}
              </option>
            </select>
          </ValidationProvider>
          <p class="form-card__text form-card__text--center"><button class="btn btn-text" @click="modal.hide('subscribe') & modal.show('stripe')">Pay with new card</button></p>
          <v-button class="btn btn-primary btn-block form-card__button" @click="subscribeWithExistingCard(cardId)" group="subscribe" v-if="isDowngrading">submit</v-button>
          <v-button class="btn btn-primary btn-block form-card__button" @click="subscribeWithExistingCard(cardId)" group="subscribe" v-else>{{ subscribeModalPrice }}</v-button>
        </div>
      </modal>
      <modal title="Warning" subtitle="Existing Subscription" v-if="modal.isVisible('itunes-warning')" @close="modal.hide('itunes-warning')" :show-close-button="true" class="modal__warning">
        <p>We noticed you already have an active subscription via in-app purchase.</p>
        <p>Subscribing via this website does not automatically cancel your existing subscription.</p>
        <p class="modal__text modal__text--small">To cancel your existing iTunes subscription, please open the subscription screen of the Fonetti app.</p>
        <template v-slot:footer>
          <button class="btn btn-cancel modal__button" @click="closeITunesModal">Continue</button>
        </template>
      </modal>
      <modal :title="modalInfo.title" v-if="modal.isVisible('info')" @close="modal.hide('info')" :show-close-button="true" class="modal__warning">
        <p v-html="modalInfo.message"></p>
        <template v-slot:footer>
          <button class="btn btn-cancel modal__button" @click="modal.hide('info')">Continue</button>
        </template>
      </modal>
      <modal title="Success" subtitle="Subscribed" v-if="modal.isVisible('success')" @close="modal.hide('success')" :show-close-button="true" class="modal__success">
        <h3 class="modal-success__heading">Subscription successful!</h3>
        <p class="modal-success__text modal-success__text--smaller">Your subscription will automatically renew on the:</p>
        <p class="modal__text modal__text--bold modal__text--bigger modal__text--center">{{ nextPaymentDate }}</p>
        <div class="modal__text-footer">
          <p class="modal-success__text modal__text--small" v-if="$store.state.user.readers.length > 0">You should close the Fonetti application and reopen it for your subscription to download to your device.</p>
          <p class="modal__text modal__text--small modal__text--center" v-else>Download Fonetti from the App Store to start reading.</p>
          <p class="modal__text modal__text--small modal__text--center modal__text" v-if="isDowngrading">Your current subscription will continue until your renewal date, at which point you will be downgraded.</p>
        </div>
        <button class="modal__button btn btn-primary btn-block" @click="downloadApp()" v-if="$store.state.user.readers.length === 0">Download</button>
        <template v-slot:footer>
          <button class="btn btn-cancel modal__button" @click="modal.hide('success')">Close</button>
        </template>
      </modal>
      <!-- /MODALS -->
      <div class="subscription-header">
        <div class="subscription-header__title">{{ subscription.title }}</div>
        <template v-if="subscription.has_subscription">
          <div class="subscription-header__status sm-none">
            <div class="subscription-header__status--inner">
              subscribed
            </div>
          </div>
        </template>
        <template v-else>
          <div class="subscription-header__promo sm-none" v-if="displayPromocodeComponent">
              <component :is="promoComponent" :duration-length="this.applicablePromocodeComponent.duration_length" :discount-value="this.applicablePromocodeComponent.discount_value"></component>
          </div>
        </template>
      </div>
      <div class="subscription-description">{{ subscription.description }}</div>
      <div class="subscription-header__promo sm-only" v-if="displayPromocodeComponent">
        <component :is="promoComponent" :duration-length="this.applicablePromocodeComponent.duration_length" :discount-value="this.applicablePromocodeComponent.discount_value"></component>
      </div>
      <template v-if="((currentUserSubscription && !subscription.is_awaiting_renewal) || !currentUserSubscription) && !hasSubscriptionAwaitingRenewal">
        <template v-if="(archivedSubscription === null)">
          <promocode-form v-if="promocodeFormDisplayed" v-model="promocodeForm" @validated="callPreviewPromocode(promocodeForm.code)"></promocode-form>
          <div class="promocode__summary" v-if="promocodePrettyDescriptionDisplayed">
            <p v-html="promocodePrettyDescription"></p> <v-button class="btn btn-primary" group="promocode" @click="callApplyPromocode(promocode.code)" v-if="currentUserSubscription !== null">apply</v-button> <v-button class="btn btn-primary" group="promocode" @click="callClearPromocode">cancel</v-button>
          </div>
        </template>
        <template v-if="archivedSubscription && !archivedSubscription.active_price.is_cancelled">
          <template v-if="currentUserSubscription && currentUserSubscription.renewal_status !== 'cancelled'">
            <div class="promocode__summary text-center" :style="{'text-transform': 'none'}">
              This plan is no longer available. Please cancel your subscription if you wish to see our other plans otherwise you will continue to have access to the Fonetti library as long as this subscription is renewed.
            </div>
          </template>
        </template>
        <template v-if="!hasSubscription">
          <SegmentedControls
             name="selected-currency"
             :controls="currencyControls"
             @update-value="handleChangeCurrency($event)"
          />
        </template>
        <div class="subscription-badges">
          <template v-if="archivedSubscription && archivedSubscription.active_price.is_cancelled === false">
            <subscription-badge
            :price="archivedSubscription.active_price"
            :currency="CURRENCIES[CURRENCIES_SHORTNAME_TO_COUNTRY_ID[archivedSubscription.active_price.currency]]"
            :is-disabled="badgeDisabled(archivedSubscription.active_price)"
            :max-readers="archivedSubscription.max_devices"
            :show-variant="true"
            @click="showInfoModal(archivedSubscription.active_price)"
            @selected="selectPrice($event, { price: archivedSubscription.active_price, variant: archivedSubscription })">
            </subscription-badge>
          </template>
          <template v-else>
            <template v-for="variant in subscriptionVariantsToDisplay">
              <template v-for="price in variant.prices">
                <subscription-badge
                    :key="price.id"
                    v-if="price.currency == selectedCurrency.short_name"
                    :currency="selectedCurrency"
                    :price="price"
                    :is-disabled="badgeDisabled(price)"
                    :max-readers="variant.max_devices"
                    @click="showInfoModal(price)"
                    @selected="selectPrice($event, { price, variant })"></subscription-badge>
              </template>
            </template>
          </template>
        </div>
        <div class="subscription-footer">
          <div class="subscription-footer__info" v-html="nextPaymentText"></div>
          <div class="subscription-footer__info" v-html="currentDiscountText"></div>
          <div class="subscription-footer__promo" v-if="priceSelected">
            {{ defaultPromocodePrettyDescription }}
          </div>
          <div class="subscription-footer__promo" v-if="showProratedPrice">
            Your next payment will be prorated to {{ `${selectedCurrency.symbol}${proratedPrice}` }}
          </div>
          <div class="subscription-footer__cancel" v-if="shouldShowCancelButton">
            <a @click="modal.show('unsubscribe')">cancel subscription</a>
          </div>
           <div class="subscription-footer__cancel" v-if="hasITunesSubscription">
            <p>To cancel your existing iTunes subscription, please open the subscription screen of the Fonetti app.</p>
          </div>
          <div class="mt-40" v-if="subscription.has_subscription && isSubscriptionCancelled && currentSubscriptionVariant.is_archived === false">
            <v-button @click="modal.show('reactivate')" class="btn btn-primary">reactivate subscription</v-button>
          </div>
          <div class="subscription-footer__cancel" v-if="isInDowngradingPeriod && archivedSubscription === null">
            Your subscription will downgrade to monthly on the {{ nextPaymentDate }}<br>
            <v-button class="btn btn-primary" group="subscribe" @click="cancel">cancel downgrading</v-button>
          </div>
          <div class="subscription-footer__subscribe" v-if="priceSelected">
            <v-button class="btn btn-primary" group="subscribe" @click="subscribe">subscribe</v-button>
          </div>
        </div>
      </template>
      <template v-else-if="anyPausedSubscription">
        <div>
          <div class="subscription-footer">
            <div class="subscription-footer__info">
              <p>Your paid subscription is currently on hold, and will automatically continue on {{pausedInvoiceNextPaymentDate}}.</p>
              <div class="subscription-footer__cancel">
                <a @click="modal.show('autorenew')">Click here to turn off the auto-renewal</a>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="subscription-footer">
          <div class="subscription-footer__info" :class="{highlight: isGoogleOrItunesSubscription}">{{ manageSubscriptionMessage }}</div>
          <template v-if="!isGoogleOrItunesSubscription">
            <div class="subscription-footer__subscribe">
              <a class="btn btn-primary" @click="$router.push({ name: 'payments' })">manage payment methods</a>
            </div>
            <div class="subscription-footer__cancel">
              <a @click="modal.show('cancel')">cancel subscription</a>
            </div>
          </template>
        </div>
      </template>
    </section>
    <section class="subscription-page" v-else-if="ready && hasManualSubscription">
      <span v-if="hasChallengeSubscription">You currently have a complimentary subscription from joining our reading challenge. Please return once that subscription has expired or contact us for further support.</span>
      <span v-else>You have a bespoke subscription to this package. Please contact us to amend or query this subscription.</span>
    </section>
  </section>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import _ from 'lodash';
import PromocodeForm from '@/components/forms/PromocodeForm';
import SubscriptionBadge from '@/components/SubscriptionBadge';
import FreeTrial from '@/components/ui/FreeTrial';
import Coupon from '@/components/ui/Coupon';
import StripeCard from '@/components/StripeCard';
import Modal from '@/components/utils/Modal';
import ModalJS from '@/components/classes/Modal';
import PromocodeMixin from '@/components/mixins/PromocodeMixin';
import Button from '@/components/utils/Button';
import SubscriptionMixin from '@/components/mixins/SubscriptionMixin';
import CustomerMixin from '@/components/mixins/CustomerMixin';
import HelpersMixin from '@/components/mixins/HelpersMixin';
import UserMixin from '@/components/mixins/UserMixin';
import StripeMixin from '@/components/mixins/StripeMixin';
import pluralize from 'pluralize';
import SegmentedControls from '@/components/SegmentedControls';
import moment from 'moment';

const durations = {
  DEFAULT: 'default',
  MONTHLY: 'month',
  YEARLY: 'year',
};

const CURRENCIES = {
  GB: {
    name: 'British pound sterling',
    short_name: 'GBP',
    symbol: '£',
    round_prices: false,
  },
  IN: {
    name: 'Indian Rupee',
    short_name: 'INR',
    symbol: '₹',
    round_prices: true,
  },
};

const CURRENCIES_SHORTNAME_TO_COUNTRY_ID = {
  INR: 'IN',
  GBP: 'GB',
};

const PACKAGE_ID = 'fonetti-premium-library';

const DEFAULT_MANAGE_SUBSCRIPTION_MESSAGE = 'This subscription is pending renewal - You need to add a new payment card.';

export default {
  name: 'subscription',
  mixins: [PromocodeMixin, SubscriptionMixin, CustomerMixin, HelpersMixin, UserMixin, StripeMixin],
  components: {
    PromocodeForm,
    SubscriptionBadge,
    FreeTrial,
    Coupon,
    Modal,
    StripeCard,
    'v-button': Button,
    SegmentedControls,
  },
  data() {
    return {
      subscription: null,
      customer: null,
      modal: new ModalJS({
        unsubscribe: false,
        subscribe: false,
        stripe: false,
        itunesWarning: false,
        success: false,
        addPaymentCard: false,
        reactivate: false,
        info: false,
        cancel: false,
        autorenew: false,
      }),
      promocodeForm: {
        code: null,
      },
      priceSelected: false,
      selectedPrice: null,
      selectedVariant: null,
      cardId: null,
      nextModalToOpen: null,
      isDowngrading: false,
      hasActiveDiscount: false,
      ready: false,
      applicablePromocodeComponent: null,
      promocodeLoading: false,
      key: 1,
      modalInfo: {},
      subscriptionDurationToShow: durations.DEFAULT,
      CURRENCIES,
      CURRENCIES_SHORTNAME_TO_COUNTRY_ID,
      selectedCurrency: CURRENCIES.GB,
      disabledDuration: {
        month: false,
        year: false,
      },
      disabledCurrencies: {},
      currencyControls: [],
    };
  },
  created() {
    this.handleUser();
    this.$bus.$on('promocode-cleared', () => {
      this.callGetSubscription();
    });
  },
  mounted() {
    // set default currency
    let selectedCurrency = null;
    const currencyKeys = Object.keys(CURRENCIES);
    // if the user has a country set:
    // set selected currency into that country
    if (this.user.country !== 'GB' && this.user.country !== null) {
      selectedCurrency = CURRENCIES[this.user.country];
    }

    // if the user has a current subscription:
    // set selected currency into the selected plan's currency
    for (let i = 0; i < currencyKeys.length; i++) {
      if (this.currentSubscriptionCurrency) {
        if (CURRENCIES[currencyKeys[i]].short_name === this.currentSubscriptionCurrency) {
          selectedCurrency = CURRENCIES[currencyKeys[i]];
        }
      }
      this.currencyControls.push({
        value: currencyKeys[i],
        text: `${CURRENCIES[currencyKeys[i]].short_name} ${CURRENCIES[currencyKeys[i]].symbol}`,
        isActive: selectedCurrency !== null ? selectedCurrency.symbol === CURRENCIES[currencyKeys[i]].symbol : currencyKeys[i] === 'GB',
        disabled: false,
        disabled_message: `${CURRENCIES[currencyKeys[i]].short_name} is not available with the current promotion code`,
        currency: CURRENCIES[currencyKeys[i]].short_name,
      });
    }

    // update selected currency state;
    if (selectedCurrency) {
      this.selectedCurrency = selectedCurrency;
    }

    // set all subscription controls disabled state to false
    this.enableSubscriptionControls();

    document.body.classList.remove('modal-open');
  },
  beforeDestroy() {
    this.$bus.$off('promocode-cleared');
  },
  methods: {
    handleDurationChange(e) {
      switch (e) {
        case 'year':
          this.subscriptionDurationToShow = durations.YEARLY;
          break;
        case 'month':
          this.subscriptionDurationToShow = durations.MONTHLY;
          break;
        default:
          this.subscriptionDurationToShow = durations.DEFAULT;
      }
      this.priceSelected = false;
    },
    handleUser() {
      this.getUser(PACKAGE_ID)
        .then((user) => {
          user.subscriptions.forEach((sub) => {
            if (sub.upcoming_invoice !== null && sub.upcoming_invoice.discount !== null) {
              this.hasActiveDiscount = true;
            }
          });
          this.ready = true;
        })
        .catch((error) => {
          this.flashMessage(error, 'error');
        });
    },
    init() {
      this.getCustomer()
        .then((customer) => {
          this.customer = customer;
          this.cardId = this.defaultCardId;
          if (this.customer.subscriptions[0].interval) {
            this.subscriptionDurationToShow = this.customer.subscriptions[0].interval;
          } else {
            this.subscriptionDurationToShow = 'month';
          }
        })
        .catch(() => { })
        .finally(() => {
          this.callGetSubscription();
        });
    },
    downloadApp() {
      window.open('https://apps.apple.com/app/fonetti/id1332609392', '_blank');
    },
    callGetSubscription(cb = null) {
      this.getSubscription(1, this.subscriptionParams())
        .then((subscription) => {
          this.subscription = subscription;
          this.applicablePromocodeComponent = this.getApplicablePromocodeComponent();
          if (cb !== null && typeof cb === 'function') {
            cb();
          }
          if (this.isSubscriptionCancelled && !_.isEmpty(this.queryCode)) {
            this.clearPromocode();
          }
          if (this.selectedPrice !== null) {
            this.subscription.variants.forEach((variant) => {
              variant.prices.forEach((price) => {
                if (price.id === this.selectedPrice.id) {
                  this.selectPrice(true, { price, variant });
                }
              });
            });
          }
        })
        .catch((error) => {
          this.flashMessage(error, 'error');
        });
    },
    callPreviewPromocode(code) {
      this.promocodeLoading = true;
      this.buttonGroupIsLoading('promocode', true);
      this.previewPromocode(code, 'subscription')
        .then(() => {
          this.flashMessage();
        })
        .catch((error) => {
          this.flashMessage(error, 'error');
        })
        .finally(() => {
          this.promocodeForm.code = null;
          this.callGetSubscription(() => {
            this.promocodeLoading = false;
            this.buttonGroupIsLoading('promocode', false);
          });
        });
    },
    callClearPromocode() {
      this.buttonGroupIsLoading('promocode', true);
      this.clearPromocode();
      this.callGetSubscription(() => {
        this.buttonGroupIsLoading('promocode', false);
        if (this.selectedPrice !== null) {
          this.subscription.variants.forEach((variant) => {
            variant.prices.forEach((price) => {
              if (price.id === this.selectedPrice.id) {
                this.selectPrice(true, { price, variant });
              }
            });
          });
        }
      });
      this.enableSubscriptionControls();
    },
    callApplyPromocode(code) {
      this.buttonGroupIsLoading('promocode', true);
      this.applyPromocode(code, this.currentUserSubscription)
        .then(() => {
          this.hasActiveDiscount = true;
          this.flashMessage('The promocode has been applied', 'success');
          this.handleUser(); // reload the user
          this.callGetSubscription();
        })
        .catch((error) => {
          this.flashMessage(error, 'error');
        })
        .finally(() => {
          this.promocodeForm.code = null;
          this.buttonGroupIsLoading('promocode', false);
        });
    },
    selectPrice(payload, { price, variant }) {
      if (payload === true) {
        this.isDowngrading = this.currentSubscriptionVariant !== null && variant.max_devices < this.currentSubscriptionVariant.max_devices;
        this.priceSelected = payload;
        this.selectedPrice = price;
        this.selectedVariant = variant;
      } else {
        this.resetSelection();
      }
    },
    resetSelection() {
      this.priceSelected = false;
      this.selectedPrice = null;
      this.selectedVariant = null;
      this.$bus.$emit('price-cleared');
    },
    subscribe() {
      let modalToOpen = 'stripe';
      if (!_.isEmpty(this.customer) && !_.isEmpty(this.customer.cards)) {
        modalToOpen = 'subscribe';
      }

      if (this.hasITunesSubscription) {
        this.nextModalToOpen = modalToOpen;
        this.modal.show('itunes-warning');
      } else if (this.userReadersCount > this.selectedVariant.max_devices) {
        this.modal.show('max-readers');
      } else if (!this.subscription.has_subscription) {
        this.modal.show(modalToOpen);
      }

      // meaning the user wants to update their subscription
      if (this.subscription.has_subscription) {
        this.updateSubscription();
      }
    },
    hideSubscribeModals() {
      this.modal.hide('stripe');
      this.modal.hide('subscribe');
    },
    closeITunesModal() {
      this.modal.hide('itunes-warning');
      this.modal.show(this.nextModalToOpen);
    },
    updateSubscription() {
      const data = {
        provider_ref: this.selectedPrice.payment_provider_ref,
        payment_provider_subscription_id: this.currentStripeSubscription.id,
      };
      this.buttonGroupIsLoading('subscribe', true);
      this.$api.post('/subscriptions/stripe/update', data)
        .then((response) => {
          const paymentIntent = _.get(response, 'data.subscription.latest_invoice.payment_intent', null);
          this.validatePayment(paymentIntent, this.defaultCardId)
            .then((status) => {
              if (status === 'succeeded') {
                return Promise.resolve();
              }
              return this.$api.post('/subscriptions/stripe/update', data);
            })
            // .then(() => this.getCustomer())
            .then(() => {
              this.paymentMade();
            })
            .catch(() => this.$api.post('/subscriptions/stripe/update', data))
            .then((res) => {
              this.flashMessage(res, 'success');
            })
            .catch((error) => {
              this.flashMessage(error, 'error');
            })
            .finally(() => {
              this.cleanUpAfterPaymentAttempt();
            });
        })
        .catch((error) => {
          this.flashMessage(error, 'error', 'stripe');
          this.cleanUpAfterPaymentAttempt();
        });
    },
    subscribeWithNewCard({ card }) {
      window.scrollTo(0, 0);
      this.stripe.createPaymentMethod({
        type: 'card',
        card,
      })
        .then((result) => {
          if (result.error) {
            this.buttonGroupIsLoading('all', false);
            this.flashMessage(result.error.message, 'error', 'stripe');
          } else {
            const code = _.get(this.selectedPrice, 'promocode_data.applicable_promocode.code', null);
            const params = {
              payment_data: {
                provider_ref: this.selectedPrice.payment_provider_ref,
                card_id: result.paymentMethod.id,
                email: this.$store.state.user.email,
                code,
              },
            };
            this.makePayment(params, result.paymentMethod.id);
          }
        });
    },
    subscribeWithExistingCard(cardId) {
      window.scrollTo(0, 0);
      const code = _.get(this.selectedPrice, 'promocode_data.applicable_promocode.code', null);
      const params = {
        payment_data: {
          provider_ref: this.selectedPrice.payment_provider_ref,
          card_id: cardId,
          email: this.$store.state.user.email,
          code,
        },
      };
      this.makePayment(params, cardId);
    },
    makePayment(params, pmId) {
      this.buttonGroupIsLoading('subscribe', true);
      this.$api.post('/subscriptions/process_payment/stripe', params)
        .then((response) => {
          const paymentIntent = _.get(response, 'data.subscription.latest_invoice.payment_intent', null);
          this.validatePayment(paymentIntent, pmId)
            .then(() => {
              this.paymentMade();
            })
            .catch((err) => {
              this.flashMessage(err, 'error', 'stripe');
            })
            .finally(() => {
              this.cleanUpAfterPaymentAttempt();
            });
        })
        .catch((err) => {
          this.flashMessage(err, 'error', 'stripe');
          this.cleanUpAfterPaymentAttempt();
        });
    },
    cleanUpAfterPaymentAttempt() {
      this.resetSelection();
      this.buttonGroupIsLoading('all', false);
      this.clearPromocode();
      this.$store.commit('RESET_PROMOCODE');
    },
    paymentMade() {
      this.hideSubscribeModals();
      this.getCustomer()
        .then((customer) => {
          this.customer = customer;
          this.modal.show('success');
          this.handleUser();
          if (this.customer.subscriptions[0].interval) {
            this.subscriptionDurationToShow = this.customer.subscriptions[0].interval;
          } else {
            this.subscriptionDurationToShow = 'month';
          }
          this.callGetSubscription();
        })
        .catch((error) => {
          this.flashMessage(error, 'error');
        });
    },
    unsubscribe(immediate = false) {
      this.buttonGroupIsLoading('subscribe', true);
      this.$api.delete('/subscriptions/stripe', { data: { payment_provider_subscription_id: this.currentStripeSubscription.id, immediate } })
        .then(() => {
          this.getCustomer()
            .then((customer) => {
              this.customer = customer;
              this.callGetSubscription(() => {
                if (immediate) {
                  this.key++;
                }
              });
            })
            .catch((error) => {
              this.flashMessage(error, 'error');
            });
        })
        .catch((error) => {
          this.flashMessage(error, 'error');
        })
        .finally(() => {
          this.modal.hide('unsubscribe');
          this.modal.hide('autorenew');
          this.modal.hide('cancel');
          this.buttonGroupIsLoading('subscribe', false);
          this.$forceUpdate();
        });
    },
    cancelAutoRenew(immediate = false) {
      this.buttonGroupIsLoading('subscribe', true);
      const pausedSubscription = _.find(this.user.subscriptions, { renewal_status: 'paused', payment_provider: 'stripe' });
      const pausedSubscriptionId = pausedSubscription.payment_provider_subscription_id;
      this.$api.delete('/subscriptions/stripe', { data: { payment_provider_subscription_id: pausedSubscriptionId, immediate } })
        .then(() => {
          this.getCustomer()
            .then((customer) => {
              this.customer = customer;
              this.callGetSubscription(() => {
                if (immediate) {
                  this.key++;
                }
              });
            })
            .catch((error) => {
              this.flashMessage(error, 'error');
            });
        })
        .catch((error) => {
          this.flashMessage(error, 'error');
        })
        .finally(() => {
          this.modal.hide('autorenew');
          this.modal.hide('cancel');
          this.buttonGroupIsLoading('subscribe', false);
          this.$forceUpdate();
        });
    },
    cancel() {
      this.buttonGroupIsLoading('subscribe', true);
      this.$api.post('/subscriptions/stripe/cancel_update', { payment_provider_subscription_id: this.currentPaymentProviderSubscriptionId })
        .then(() => {
          this.getCustomer()
            .then((customer) => {
              this.customer = customer;
              this.callGetSubscription();
            })
            .catch((error) => {
              this.flashMessage(error, 'error');
            });
        })
        .catch((error) => {
          this.flashMessage(error, 'error');
        })
        .finally(() => {
          this.buttonGroupIsLoading('subscribe', false);
        });
    },
    reactivate() {
      this.buttonGroupIsLoading('subscribe', true);
      this.$api.post('/subscriptions/stripe/reactivate', { payment_provider_subscription_id: this.currentStripeSubscription.id })
        .then((response) => {
          this.key++;
          this.flashMessage(response, 'success');
        })
        .catch((error) => {
          this.flashMessage(error, 'error');
        })
        .finally(() => {
          this.buttonGroupIsLoading('subscribe', false);
          this.modal.hide('reactivate');
          this.init();
        });
    },
    subscriptionParams() {
      const params = {};
      params.code = this.promocode === null ? this.queryCode : this.promocode.code;
      params.customer = _.get(this.customer, 'id', null);
      params.subscription = this.currentPaymentProviderSubscriptionId;
      return params;
    },
    badgeDisabled(price) {
      if (this.isInDowngradingPeriod) {
        return true;
      }
      if (this.isSubscriptionCancelled) {
        return true;
      }
      if (this.currentSubscriptionPrice !== null && price.payment_provider_ref !== this.currentSubscriptionPrice.payment_provider_ref && this.hasCurrentDiscount) {
        return true;
      }

      // previewing promocode + attempting to change plan = not allowed
      if (this.currentUserSubscription !== null && this.promocode !== null && !price.has_subscription) {
        return true;
      }

      if (this.promocode !== null) {
        if (price.promocode_data.is_discountable === false) {
          return true;
        }
        if (price.promocode_data.applicable_promocode.code !== this.promocode.code) {
          return true;
        }
      }

      return false;
    },
    badgeDisabledReasonMessage(price) {
      if (this.isInDowngradingPeriod) {
        return {
          title: 'Plan change unavailable',
          message: 'We cannot change your plan whilst you are awaiting a downgrade. If you wish to keep your current plan, press the cancel downgrade button.',
        };
      }

      if (this.archivedSubscription && this.archivedSubscription.active_price.is_cancelled) {
        return {
          title: 'Plan change unavailable',
          message: 'We cannot change your plan whilst you are in your cancellation period.',
        };
      }

      if (this.isSubscriptionCancelled) {
        return {
          title: 'Plan change unavailable',
          message: 'We cannot change your plan whilst you are in your cancellation period. If you wish to stay subscribed, please reactivate your subscription and then you can change plans if you wish.',
        };
      }

      if (this.currentSubscriptionPrice !== null && price.payment_provider_ref !== this.currentSubscriptionPrice.payment_provider_ref && this.hasCurrentDiscount) {
        return {
          title: 'Plan change unavailable',
          message: 'Unfortunately we cannot change your plan when you have an active discount applied too it. You can either try again once your discount ends or cancel your subscription to re-subscribe.',
        };
      }

      // previewing promocode + attempting to change plan = not allowed
      if (this.currentUserSubscription !== null && this.promocode !== null && !price.has_subscription) {
        return {
          title: 'Plan change unavailable',
          message: 'We are unable to support plan changing whilst applying a promotion code.',
        };
      }

      if (this.promocode !== null) {
        if (price.promocode_data.is_discountable === false) {
          return {
            title: 'Plan unavailable',
            message: 'This plan is not available with the current promotion code. Remove the applied promotion code to select this plan.',
          };
        }
        if (price.promocode_data.applicable_promocode.code !== this.promocode.code) {
          return {
            title: 'Plan unavailable',
            message: 'This plan is not available with the current promotion code. Remove the applied promotion code to select this plan.',
          };
        }
      }

      return null;
    },
    getApplicablePromocodeComponent() {
      this.flashMessage();
      if (this.subscription === null || this.subscription.has_subscription || this.subscription.is_awaiting_renewal) {
        return null;
      }
      // code used ->
      // if user not yet subscribed,
      // we display that promocode
      // even if applies to only 1 price
      // and if it applies to the current user
      // else go to "no code used"
      if (this.promocode !== null) {
        let display = false;
        // eslint-disable-next-line no-unused-vars
        let promocodeIsInvalid = true;
        _.forEach(this.subscription.variants, (variant) => {
          const found = _.find(variant.prices, (price) => price.promocode_data.applicable_promocode !== null && price.promocode_data.applicable_promocode.code === this.promocode.code);
          if (found !== undefined) {
            display = true;
            promocodeIsInvalid = false;
          }
        });

        if (promocodeIsInvalid) {
          this.flashMessage('This promotion is not valid on this subscription.', 'error');
          this.clearPromocode();
        }

        return display ? this.promocode : null;
      }

      // no code used ->
      // the default promocode is used
      // if each price has the same default promocode
      // and if it applies to the current user
      let promocodeToDisplay = null;
      let display = true;
      _.forEach(this.subscription.variants, (variant) => {
        _.forEach(variant.prices, (price) => {
          if (price.promocode_data.is_discountable === true) {
            if (promocodeToDisplay !== null && promocodeToDisplay.id !== price.promocode_data.applicable_promocode.id) {
              display = false;
            }
            promocodeToDisplay = price.promocode_data.applicable_promocode;
          } else {
            display = false;
          }
        });
      });
      return display ? promocodeToDisplay : null;
    },
    showInfoModal(price) {
      this.modalInfo = this.badgeDisabledReasonMessage(price);
      if (this.modalInfo !== null) {
        this.modal.show('info');
      }
    },
    handleChangeCurrency(e) {
      for (let i = 0; i < this.currencyControls.length; i++) {
        if (this.currencyControls[i].currency === CURRENCIES[e].short_name) {
          this.currencyControls[i].isActive = true;
        } else {
          this.currencyControls[i].isActive = false;
        }
      }
      this.selectedCurrency = CURRENCIES[e];
      this.priceSelected = false;
    },
    disableSubscriptionControls() {
      for (let i = 0; i < this.currencyControls.length; i++) {
        this.currencyControls[i].disabled = true;
      }
    },
    enableSubscriptionControls() {
      for (let i = 0; i < this.currencyControls.length; i++) {
        this.currencyControls[i].disabled = false;
      }
    },
  },
  computed: {
    ...mapState(['queryCode', 'user', 'stripe']),
    ...mapGetters(['userReadersCount']),
    currentUserSubscriptionProvider() {
      if (this.currentUserSubscription) {
        return this.currentUserSubscription.payment_provider;
      }
      return null;
    },
    isGoogleOrItunesSubscription() {
      if (this.currentUserSubscriptionProvider) {
        return this.currentUserSubscriptionProvider === 'google' || this.currentUserSubscriptionProvider === 'itunes';
      }
      return false;
    },
    manageSubscriptionMessage() {
      let message = DEFAULT_MANAGE_SUBSCRIPTION_MESSAGE;
      if (this.isGoogleOrItunesSubscription) {
        let managingStore = 'Google Play Store';
        if (this.currentUserSubscriptionProvider === 'itunes') {
          managingStore = 'Apple App Store';
        }
        message = `Please manage your subscription in the Fonetti app or the ${managingStore}.`;
      }

      return message;
    },
    anyPausedSubscription() {
      return this.user.subscriptions.filter((s) => s.renewal_status === 'inactive').length > 0;
    },
    hasSubscriptionAwaitingRenewal() {
      return !this.currentUserSubscription && (this.user.subscriptions.filter((s) => s.renewal_status === 'active').length > 0);
    },
    prettySubscriptionExpiry() {
      return moment(this.currentUserSubscription.expires_at).format('Mo MMMM YYYY');
    },
    shouldShowCancelButton() {
      if (this.hasITunesSubscription) {
        return false;
      } if (this.subscription.has_subscription && !this.isSubscriptionCancelled) {
        return true;
      }
      return false;
    },
    subscriptionVariantsToDisplay() {
      const newArray = JSON.parse(JSON.stringify(this.subscription.variants)); // create a new array from the vue object
      for (let i = 0; i < newArray.length; i++) {
        newArray[i].prices = newArray[i].prices.filter((price) => price.is_archived === false);
      }
      return newArray;
    },
    promoComponent() {
      if (this.applicablePromocodeComponent.type === 'free_trial') {
        return 'free-trial';
      }
      return 'coupon';
    },
    subscribeModalTitle() {
      if (this.selectedVariant === null) return '';
      return this.selectedVariant.max_devices > 1 ? 'family' : 'single';
    },
    subscribeModalPrice() {
      let price = null;
      if (this.proratedPrice !== null) {
        price = this.proratedPrice;
      } else {
        price = this.getAmountFromPrice(this.selectedPrice);
      }

      return `pay ${this.selectedCurrency.symbol}${price}`;
    },
    isSubscriptionCancelled() {
      return _.get(this.currentSubscriptionPrice, 'is_cancelled', null);
    },
    currentSubscriptionPrice() {
      let price = null;
      _.forEach(this.subscription.variants, (variant) => _.forEach(variant.prices, (p) => {
        if (p.has_subscription || p.is_awaiting_renewal) {
          price = p;
        }
      }));
      return price;
    },
    currentSubscriptionVariant() {
      let variant = null;
      _.forEach(this.subscription.variants, (v) => {
        if (v.has_subscription) {
          variant = v;
        }
      });
      return variant;
    },
    currentStripeSubscription() {
      if (this.subscription === null || this.customer === null || _.isEmpty(this.customer.subscriptions)) {
        return null;
      }

      const price = this.currentSubscriptionPrice;

      if (price === null) {
        return null;
      }

      const sub = _.find(this.customer.subscriptions, (s) => s.provider_ref === price.payment_provider_ref);
      return sub === undefined ? null : sub;
    },
    currentUserSubscription() {
      if (this.$store.state.user.subscriptions.length > 0) {
        const { subscriptions } = this.$store.state.user;
        const activeSubscriptions = subscriptions.filter((s) => s.renewal_status === 'active' && !s.has_expired);
        const unExpiredCancelledSubscriptions = subscriptions.filter((s) => s.renewal_status === 'cancelled' && !s.has_expired);
        const [subscription] = activeSubscriptions;
        const [cancelledSubscription] = unExpiredCancelledSubscriptions;
        return subscription || cancelledSubscription || null;
      }
      return null;
    },
    archivedSubscription() {
      if (this.currentUserSubscription) {
        const newArray = JSON.parse(JSON.stringify(this.subscription.variants)); // create a new array from the vue object
        for (let i = 0; i < newArray.length; i++) {
          if (newArray[i].id === this.currentUserSubscription.package_variant_id) {
            const { prices } = newArray[i];
            for (let j = 0; j < prices.length; j++) {
              if (prices[j].id === this.currentUserSubscription.package_variant_price_id) {
                if (prices[j].is_archived) {
                  newArray[i].active_price = prices[j];
                  return newArray[i];
                }
              }
            }
          }
        }
      }
      return null;
    },
    nextPaymentText() {
      const stripeSubscription = this.currentStripeSubscription;
      let userSubscription = null;
      if (this.currentUserSubscription) {
        userSubscription = this.currentUserSubscription;
      }

      if (stripeSubscription === null || userSubscription === null) {
        return null;
      }
      if (stripeSubscription.cancel_at !== null) {
        const date = `${this.$options.filters.moment(stripeSubscription.cancel_at)}`;
        return `Your subscription is set to cancel on the <span>${date}</span>.`;
      }

      const upcomingInvoice = userSubscription.upcoming_invoice;
      if (upcomingInvoice === null) {
        return null;
      }
      if (upcomingInvoice.next_payment_attempt) {
        const date = `${this.$options.filters.moment(upcomingInvoice.next_payment_attempt)}`;
        return `Your next payment of <strong>${this.selectedCurrency.symbol}${upcomingInvoice.amount_due}</strong> is estimated to be due on the ${date}.`;
      }

      return 'No payments set up';
    },
    nextPaymentDate() {
      if (this.currentUserSubscription && this.currentUserSubscription.upcoming_invoice) {
        return moment.unix(this.currentUserSubscription.upcoming_invoice.next_payment_attempt).format('Do MMMM YYYY');
      }
      if (this.currentUserSubscription) {
        return moment(this.currentUserSubscription.expires_at).format('Do MMMM YYYY');
      }
      return null;
    },
    currentDiscountText() {
      const discount = this.currentDiscount;
      if (discount === null || this.isSubscriptionCancelled) {
        return null;
      }

      return `Your next month will use the ${discount.name} coupon.`;
    },
    hasCurrentDiscount() {
      return this.currentDiscount !== null;
    },
    currentDiscount() {
      const userSubscription = this.currentUserSubscription ? this.currentUserSubscription : null;
      if (userSubscription === null) {
        return null;
      }

      const upcomingInvoice = userSubscription.upcoming_invoice;
      if (upcomingInvoice === null || upcomingInvoice.discount === null) {
        return null;
      }

      return upcomingInvoice.discount.coupon;
    },
    currentSubscriptionNextRenewalDate() {
      const stripeSubscription = this.currentStripeSubscription;
      if (stripeSubscription === null) {
        return null;
      }
      return this.$options.filters.moment(stripeSubscription.next_payment_date);
    },
    isInDowngradingPeriod() {
      // If your Fonetti Subscription does not match your stripe subscription, then it's a plan change (and plan changes only stay pending for downgrades)
      return this.currentSubscriptionPrice !== null && this.currentStripeSubscription === null;
    },
    hasITunesSubscription() {
      let hasITunesSubscription = false;
      this.user.subscriptions.forEach((subscription) => {
        if (subscription.package_id === this.subscription.id && subscription.payment_provider === 'itunes') {
          hasITunesSubscription = true;
        }
      });
      return hasITunesSubscription;
    },
    hasManualSubscription() {
      if (this.subscription === null) {
        return false;
      }
      if (this.subscription.is_awaiting_renewal) {
        return false;
      }
      if (!this.subscription.has_subscription) {
        return false;
      }
      let hasManualSubscription = false;
      this.user.subscriptions.forEach((subscription) => {
        if (subscription.package_id === this.subscription.id && subscription.payment_provider === 'manual') {
          hasManualSubscription = true;
        }
      });
      return hasManualSubscription;
    },
    hasChallengeSubscription() {
      if (this.hasManualSubscription === false || this.hasExpiredManualSubscription) {
        return false;
      }
      const currentSubscription = this.user.subscriptions[0];
      return currentSubscription.payment_provider_subscription_id.startsWith('challenge.');
    },
    hasExpiredManualSubscription() {
      if (this.user === null) return false;
      if (this.user.subscriptions) {
        if (!this.user.subscriptions.length) return false;
        const currentSubscription = this.user.subscriptions[0];
        return currentSubscription.payment_provider === 'manual' && currentSubscription.has_expired;
      }
      return false;
    },
    currentPaymentProviderSubscriptionId() {
      if (this.currentUserSubscription) {
        return this.currentUserSubscription.payment_provider_subscription_id;
      }
      return null;
    },
    defaultPromocodePrettyDescription() {
      if (this.selectedPrice === null) {
        return null;
      }
      const promocode = _.get(this.selectedPrice, 'promocode_data.applicable_promocode', null);
      if (promocode === null) {
        return null;
      }
      const customMessages = {
        INRAC23: 'Your first year will be discounted',
      };
      if (Object.keys(customMessages).includes(promocode.code.toUpperCase())) {
        return customMessages[promocode.code.toUpperCase()];
      }
      if (promocode.type === 'free_trial') {
        return `Your first ${pluralize(promocode.duration_unit, promocode.duration_length, true)} on this subscription are free.`;
      }
      if (promocode.type === 'coupon') {
        return `Your first ${pluralize(promocode.duration_unit, promocode.duration_length, true)} on this subscription will be discounted.`;
      }
      return null;
    },
    displayPromocodeComponent() {
      const excludedCodes = ['inrac23'];
      return this.applicablePromocodeComponent !== null && !excludedCodes.includes(this.applicablePromocodeComponent.code.toLowerCase());
    },
    showProratedPrice() {
      if (this.currentSubscriptionPrice === null || this.isDowngrading || this.selectedPrice === null) {
        return false;
      }
      const proratedPrice = parseFloat(this.selectedPrice.prorated_price);
      const price = parseFloat(this.selectedPrice.price);
      return proratedPrice > 0 && price && proratedPrice !== null && proratedPrice !== this.selectedPrice.price;
    },
    proratedPrice() {
      if (this.showProratedPrice === null) {
        return null;
      }
      const proratedPrice = parseFloat(this.selectedPrice.prorated_price);
      const price = parseFloat(this.selectedPrice.price);
      return proratedPrice > 0 && price && proratedPrice !== null && proratedPrice !== this.selectedPrice.price ? proratedPrice : null;
    },
    promocodeFormDisplayed() {
      if (this.isSubscriptionCancelled) {
        return false;
      }
      if (this.promocodeLoading) {
        return true;
      }
      return this.promocode === null && !this.hasActiveDiscount && !this.isInDowngradingPeriod;
    },
    promocodePrettyDescriptionDisplayed() {
      if (this.isSubscriptionCancelled) {
        return false;
      }
      return this.promocode !== null && !this.promocodeLoading;
    },
    currentSubscriptionCurrency() {
      let subscription = null;
      if (this.user && this.user.subscriptions && this.user.subscriptions.length) {
        [subscription] = this.user.subscriptions;
      }
      if (subscription) {
        return subscription.payment_currency;
      }
      return null;
    },
  },
  watch: {
    hasActiveDiscount(newVal) {
      if (newVal) {
        this.$store.commit('RESET_PROMOCODE');
      }
    },
    ready(newVal) {
      if (newVal === true) {
        if (!this.hasActiveDiscount && this.queryCode !== '' && this.queryCode !== null) {
          this.previewPromocode(this.queryCode, 'subscription')
            .then(() => {
              this.init();
            })
            .catch((error) => {
              this.flashMessage(error, 'error');
              this.$store.commit('RESET_PROMOCODE');
              this.init();
            });
        } else {
          this.init();
        }
      }
    },
    applicablePromocodeComponent(promoCode) {
      if (promoCode !== null && this.subscriptionVariantsToDisplay.length) {
        this.disableSubscriptionControls();
        const { variants } = this.subscription;
        for (let i = 0; i < variants.length; i++) {
          const { prices } = variants[i];
          for (let j = 0; j < prices.length; j++) {
            const promoCodeData = prices[j].promocode_data;
            if (promoCodeData.is_discountable) {
              this.handleDurationChange(prices[j].duration_unit);
              const index = this.currencyControls.findIndex((control) => control.currency === prices[j].currency);
              this.currencyControls[index].disabled = false;
              this.handleChangeCurrency(CURRENCIES_SHORTNAME_TO_COUNTRY_ID[prices[j].currency], index);
            }
          }
        }
      }
    },
  },
};
</script>
