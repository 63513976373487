<template>
  <div class="wrapper wrapper--user">
    <div class="sm-none" v-if="showDesktop">
      <div class="row">
        <div class="col-md-3">
          <sidebar></sidebar>
        </div>
        <div class="col-md-9">
          <v-header></v-header>
          <router-view></router-view>
        </div>
      </div>
    </div>
    <!--MOBILE VERSION-->
    <div class="sm-only" v-if="showMobile">
      <v-header-mobile></v-header-mobile>
      <v-header></v-header>
      <div class="row">
        <div class="col-md-12">
          <router-view></router-view>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Sidebar from '@/components/Sidebar';
import Header from '@/components/Header';
import HeaderMobile from '@/components/HeaderMobile';

export default {
  name: 'user-layout',
  components: {
    Sidebar,
    'v-header': Header,
    'v-header-mobile': HeaderMobile,
  },
  computed: {
    ...mapGetters(['showMobile', 'showDesktop']),
  },
};
</script>
