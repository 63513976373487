<template>
  <section class="products-page">
    <flash-message></flash-message>
    <template v-if="multiBooks.length > 0">
      <h2 class="page-subtitle">Collections</h2>
      <product-box v-for="(product, index) in multiBooks"
                        :key="product.id"
                        :product="product"
                        :class="{'product-box--last': index === products.length - 1}"></product-box>
    </template>
    <template v-if="singleBooks.length > 0">
      <h2 class="page-subtitle">Single Books</h2>
      <product-box v-for="(product, index) in singleBooks"
                        :key="product.id"
                        :product="product"
                        :class="{'product-box--last': index === products.length - 1}"></product-box>
    </template>
  </section>
</template>

<script>
import _ from 'lodash';
import ProductMixin from '@/components/mixins/ProductMixin';
import ProductBox from '@/components/ProductBox';

export default {
  name: 'products',
  mixins: [ProductMixin],
  components: {
    ProductBox,
  },
  data() {
    return {
      products: [],
      clientSecret: null,
      selectedProduct: null,
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.init();
    });
  },
  methods: {
    init(cb) {
      this.getProducts()
        .then((products) => {
          this.products = products;
          if (typeof cb === 'function') {
            cb();
          }
        })
        .catch((error) => {
          this.flashMessage(error, 'error');
        });
    },
  },
  computed: {
    multiBooks() {
      return _.filter(this.products, (product) => product.purchase_type === 'book_multi');
    },
    singleBooks() {
      return _.filter(this.products, (product) => product.purchase_type === 'book_single');
    },
  },
};
</script>
