import _ from 'lodash';

export default {
  methods: {
    buttonGroupIsLoading(group, value) {
      const newGroup = {};
      newGroup[group] = value;
      if (group === 'all') {
        this.$store.commit('SET_ALL_LOADING_BUTTON_GROUP', value);
      } else {
        this.$store.commit('SET_LOADING_BUTTON_GROUP', newGroup);
      }
    },
    getAmountFromPrice(price, user = null) {
      if (price === null) {
        return null;
      }
      if (price.promocode_data && price.promocode_data.discounted_price !== null) {
        return price.promocode_data.discounted_price;
      }
      if (user !== null) {
        const sub = _.find(this.user.subscriptions, (s) => s.payment_provider_ref === price.payment_provider_ref);
        if (sub !== undefined) {
          if (sub.upcoming_invoice !== null) {
            return sub.upcoming_invoice.amount_due;
          }
        }
      }
      return price.price;
    },
  },
};
