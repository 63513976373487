import Vue from 'vue';
import store from '@/store';
import Router from 'vue-router';
import HomeView from '@/views/Home';
import LogoutView from '@/views/Logout';
import ForgottenPasswordView from '@/views/password/ForgottenPassword';
import ResetPasswordView from '@/views/password/ResetPassword';
import AccountView from '@/views/Account';
import Dashboard from '@/views/account/Dashboard';
import ReaderDetails from '@/views/account/ReaderDetails';
import DetailsView from '@/views/account/Details';
import AddReaderView from '@/views/account/AddReader';
import EditReaderView from '@/views/account/EditReader';
import SubscriptionsView from '@/views/account/Subscriptions';
import SubscriptionView from '@/views/account/Subscription';
import PaymentsView from '@/views/account/Payments';
import ProductsView from '@/views/account/Products';
import ProductView from '@/views/account/Product';
import AutologinView from '@/views/Autologin';
import NotFoundView from '@/views/404';

Vue.use(Router);

const isAuthenticated = (to, from, next) => {
  if (store.getters.isLoggedIn) {
    next();
    return;
  }
  const query = { ...to.query };
  query.from = to.name;
  next({ name: 'home', query });
};

const isNotAuthenticated = (to, from, next) => {
  if (!store.getters.isLoggedIn) {
    next();
    return;
  }
  const query = { ...to.query };
  next({ name: 'subscriptions', query });
};

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      component: HomeView,
      beforeEnter: isNotAuthenticated,
      meta: { layout: 'guest-layout' },
      name: 'home',
    },
    {
      path: '/logout',
      component: LogoutView,
    },
    {
      path: '/password/forgotten',
      component: ForgottenPasswordView,
      name: 'Forgotten Password',
      beforeEnter: isNotAuthenticated,
      meta: { layout: 'guest-layout' },
    },
    {
      path: '/password/reset',
      component: ResetPasswordView,
      name: 'Reset Password',
      beforeEnter: isNotAuthenticated,
      meta: { layout: 'guest-layout' },
    },
    {
      path: '/login/:token',
      component: AutologinView,
      name: 'Automatic Login',
      meta: { layout: 'guest-layout' },
    },
    {
      path: '/my',
      redirect: '/my/subscriptions',
      component: AccountView,
      beforeEnter: isAuthenticated,
      meta: { layout: 'user-layout' },
      children: [
        {
          path: '/',
          component: SubscriptionsView,
          meta: { layout: 'user-layout' },
          name: 'my account',
        },
        {
          path: 'dashboard',
          component: Dashboard,
          meta: { layout: 'user-layout' },
          name: 'dashboard',
        },
        {
          path: 'readers/add',
          component: AddReaderView,
          meta: { layout: 'user-layout' },
          name: 'add reader',
        },
        {
          path: 'readers/:readerId',
          component: ReaderDetails,
          meta: { layout: 'user-layout' },
          name: 'reader',
        },
        {
          path: 'readers/:readerId/edit',
          component: EditReaderView,
          meta: { layout: 'user-layout' },
          name: 'edit reader',
        },
        {
          path: 'subscriptions',
          component: SubscriptionView,
          meta: { layout: 'user-layout' },
          name: 'subscriptions',
        },
        // {
        //   path: 'subscriptions',
        //   component: SubscriptionsView,
        //   meta: { layout: 'user-layout' },
        //   name: 'subscriptions',
        // },
        {
          path: 'details',
          component: DetailsView,
          meta: { layout: 'user-layout' },
          name: 'details',
        },
        {
          path: 'payments',
          name: 'payments',
          component: PaymentsView,
          meta: { layout: 'user-layout' },
        },
        {
          path: 'products',
          component: ProductsView,
          meta: { layout: 'user-layout' },
          name: 'products',
        },
        {
          path: 'products/:slug',
          name: 'product',
          component: ProductView,
          meta: { layout: 'user-layout' },
        },
      ],
    },
    {
      path: '/account',
      beforeResolve: (to, from, next) => {
        next({ path: '/my/subscriptions' });
      },
      component: AccountView,
      meta: { layout: 'user-layout' },
      children: [
        {
          path: '/',
          beforeEnter: (to, from, next) => {
            next({ path: '/my/' });
          },
          component: SubscriptionsView,
          meta: { layout: 'user-layout' },
          name: 'oldaccount',
        },
        // {
        //   path: 'subscriptions/:slug',
        //   beforeEnter: (to, from, next) => {
        //     next({ path: '/my/subscriptions/:slug' });
        //   },
        //   component: SubscriptionView,
        //   meta: { layout: 'user-layout' },
        //   name: 'oldsubscription',
        // },
        {
          path: 'subscriptions',
          beforeEnter: (to, from, next) => {
            next({ path: '/my/subscriptions' });
          },
          component: SubscriptionsView,
          meta: { layout: 'user-layout' },
          name: 'oldsubscriptions',
        },
        {
          path: 'details',
          beforeEnter: (to, from, next) => {
            next({ path: '/my/details' });
          },
          component: DetailsView,
          meta: { layout: 'user-layout' },
          name: 'olddetails',
        },

        {
          path: 'payments',
          beforeEnter: (to, from, next) => {
            next({ path: '/my/payments' });
          },
          name: 'oldpayments',
          component: PaymentsView,
          meta: { layout: 'user-layout' },
        },
        {
          path: 'products',
          beforeEnter: (to, from, next) => {
            next({ path: '/my/products' });
          },
          component: ProductsView,
          meta: { layout: 'user-layout' },
          name: 'oldproducts',
        },
        {
          path: 'products/:slug',
          beforeEnter: (to, from, next) => {
            next({ path: '/my/products/:slug' });
          },
          name: 'oldproduct',
          component: ProductView,
          meta: { layout: 'user-layout' },
        },
      ],
    },
    {
      path: '/subscriptions',
      beforeEnter: (to, from, next) => {
        next({ path: '/my/subscriptions' });
      },
    },
    {
      path: '/subscriptions',
      beforeEnter: (to, from, next) => {
        next({ path: '/my/subscriptions' });
      },
    },
    {
      path: '/login',
      beforeEnter: (to, from, next) => {
        next({ path: '/my/subscriptions' });
      },
    },
    {
      // not found handler
      path: '*',
      component: NotFoundView,
      name: '',
      meta: { layout: 'guest-layout' },
    },
  ],
});
