<template>
  <div class="delete-reader-modal">
    <modal title="" v-if="modal.isVisible('deleteReader')" @close="modal.hide('deleteReader')" :show-close-button="true" class="modal__warning">
      <h3 class="text-center">Are you sure?</h3>
      <p>
        Once a profile is deleted, all information such as reading progress will be lost.
        We will not be able to recover this information for you if you change your mind.
      </p>
      <input type="text" name="confirmText" id="confirmText" class="form-control mb-3"
        v-model="confirmText" :placeholder="`Type the full profile name to confirm: ${reader.name}`">
      <div class="button-container">
          <v-button class="btn btn-primary" :disabled="!isNameConfirmed"
            @click="$emit('deleteConfirmed')" group="readersDelete">Delete Profile</v-button>
          <button class="btn btn-secondary" @click="modal.hide('deleteReader')">Cancel</button>
      </div>
    </modal>
  </div>
</template>

<script>
import Modal from '@/components/utils/Modal';
import Button from '@/components/utils/Button';

export default {
  props: ['modal', 'reader'],
  components: {
    Modal,
    'v-button': Button,
  },
  computed: {
    isNameConfirmed() {
      return this.confirmText === this.reader.name;
    },
  },
  data() {
    return {
      confirmText: '',
    };
  },
};
</script>
<style lang="scss" scope>
  .delete-reader-modal {
    input {
      background: none;
      border: none;
      border-bottom: 1px solid #5d5d5d;
      color: #5d5d5d;
      width: 100%;
      padding-bottom: 15px;
      font-weight: 300;
      font-size: 1em;
      &:focus {
        border-bottom: 1px solid #5d5d5d !important;
      }
      &::placeholder {
        color: #5d5d5d;
        opacity: 1; /* Firefox */
      }

      &:-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: #5d5d5d;
      }

      &::-ms-input-placeholder { /* Microsoft Edge */
        color: #5d5d5d;
      }
    }
    .button-container {
      display: flex;
      justify-content: center;
      .btn-primary {
        margin-right: 0.3rem;
      }
    }
  }
</style>
