<template>
  <div class="account-page">
    <router-view :key="$route.fullPath"></router-view>
  </div>
</template>

<script>
export default {
  name: 'account',
  mounted() {
    this.$store.commit('SET_LAYOUT', 'user-layout');
  },
};
</script>
