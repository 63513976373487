<template>
  <button
    class="subscription-badge"
    @click="select"
    :class="{
    'subscription-badge--is-active': isActive,
    'subscription-badge--is-disabled': price.has_subscription || isDimmed,
    'subscription-badge--is-dimmed': isDimmed,
    'subscription-badge--is-subscribed': price.has_subscription,
    'animation-trigger-year': isYear,
    'animation-trigger-month': isMonth,
    }"
    v-tooltip.top-center="{content: 'This plan is currently not available<br>click for more information', trigger: 'manual', show: canShowTooltip && showTooltip}"
    @mouseleave="showTooltip = false"
    @mouseenter="showTooltip = true"
  >
    <div class="subscription-badge__description">
      <checkmark v-if="price.has_subscription"></checkmark>
      <span v-if="showVariant" class="subscription-badge__type">{{ type }}</span>
      <span class="subscription-badge__amount">{{ `${currency.symbol}${currency.round_prices ? Math.round(currentPrice) : currentPrice}` }}</span>
      <span class="subscription-badge__interval">per {{ price.duration_unit }}</span>
      <span class="subscription-badge__promo" v-if="price.promocode_data && price.promocode_data.discounted_price">was {{ `${currency.symbol}${oldPrice}` }}</span>
    </div>
  </button>
</template>

<script>
import { mapState } from 'vuex';
import VToolTip from 'v-tooltip';
import Checkmark from '@/components/ui/Checkmark';
import HelpersMixin from '@/components/mixins/HelpersMixin';

export default {
  name: 'subscription-badge',
  mixins: [HelpersMixin],
  props: {
    price: {
      required: true,
      type: Object,
    },
    currency: {
      required: true,
      type: Object,
    },
    maxReaders: {
      required: true,
      type: Number,
    },
    isDisabled: {
      type: Boolean,
      default: false,
      required: false,
    },
    showVariant: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  components: {
    Checkmark,
    VToolTip, // eslint-disable-line
  },
  data() {
    return {
      isActive: false,
      showTooltip: false,
    };
  },
  mounted() {
    this.$bus.$on('price-selected', (priceId) => {
      if (priceId !== this.price.id) {
        this.isActive = false;
      }
    });
    this.$bus.$on('price-cleared', () => {
      this.isActive = false;
    });
  },
  methods: {
    select() {
      if (this.isDisabled || this.price.has_subscription) {
        this.$emit('click', this.price);
        return;
      }
      this.isActive = !this.isActive;
      this.$emit('selected', this.isActive);
      this.$bus.$emit('price-selected', this.price.id);
    },
  },
  computed: {
    ...mapState(['previewPromocode', 'user']),
    type() {
      return this.maxReaders > 1 ? 'multi-device' : 'single device';
    },
    currentPrice() {
      return this.getAmountFromPrice(this.price, this.user);
    },
    oldPrice() {
      return this.price.price;
    },
    isDimmed() {
      return this.isDisabled;
    },
    canShowTooltip() {
      return this.isDimmed;
    },
    isMonth() {
      return this.price.duration_unit === 'month';
    },
    isYear() {
      return this.price.duration_unit === 'year';
    },
  },
};
</script>
