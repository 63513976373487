var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{directives:[{name:"tooltip",rawName:"v-tooltip.top-center",value:({content: 'This plan is currently not available<br>click for more information', trigger: 'manual', show: _vm.canShowTooltip && _vm.showTooltip}),expression:"{content: 'This plan is currently not available<br>click for more information', trigger: 'manual', show: canShowTooltip && showTooltip}",modifiers:{"top-center":true}}],staticClass:"subscription-badge",class:{
  'subscription-badge--is-active': _vm.isActive,
  'subscription-badge--is-disabled': _vm.price.has_subscription || _vm.isDimmed,
  'subscription-badge--is-dimmed': _vm.isDimmed,
  'subscription-badge--is-subscribed': _vm.price.has_subscription,
  'animation-trigger-year': _vm.isYear,
  'animation-trigger-month': _vm.isMonth,
  },on:{"click":_vm.select,"mouseleave":function($event){_vm.showTooltip = false},"mouseenter":function($event){_vm.showTooltip = true}}},[_c('div',{staticClass:"subscription-badge__description"},[(_vm.price.has_subscription)?_c('checkmark'):_vm._e(),(_vm.showVariant)?_c('span',{staticClass:"subscription-badge__type"},[_vm._v(_vm._s(_vm.type))]):_vm._e(),_c('span',{staticClass:"subscription-badge__amount"},[_vm._v(_vm._s(("" + (_vm.currency.symbol) + (_vm.currency.round_prices ? Math.round(_vm.currentPrice) : _vm.currentPrice))))]),_c('span',{staticClass:"subscription-badge__interval"},[_vm._v("per "+_vm._s(_vm.price.duration_unit))]),(_vm.price.promocode_data && _vm.price.promocode_data.discounted_price)?_c('span',{staticClass:"subscription-badge__promo"},[_vm._v("was "+_vm._s(("" + (_vm.currency.symbol) + _vm.oldPrice)))]):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }