<template>
  <div class="checkmark">
    <fa-layers class="checkmark-circle">
      <fa icon="circle" class="checkmark-circle__circle"></fa>
      <fa icon="check" class="checkmark-circle__checkmark"></fa>
    </fa-layers>
  </div>
</template>

<script>
export default {
  name: 'checkmark',
};
</script>
