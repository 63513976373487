import _ from 'lodash';

export default {
  methods: {
    getSubscriptions(code = null) {
      return new Promise((resolve, reject) => {
        let url = '/users/me/available_subscriptions?provider=stripe';
        if (code !== null) {
          url += `&code=${code}`;
        }
        this.$api.get(url)
          .then((response) => {
            const { packages } = response.data;
            resolve(packages);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getSubscription(id, { code = null, customer = null, subscription = null }) {
      return new Promise((resolve, reject) => {
        let url = `/subscriptions/${id}?provider=stripe`;
        if (code !== null) {
          url += `&code=${code}`;
        }
        if (customer !== null) {
          url += `&customer=${customer}`;
        }
        if (subscription !== null) {
          url += `&subscription=${subscription}`;
        }
        this.$api.get(url)
          .then((response) => {
            // eslint-disable-next-line no-shadow
            const { subscription } = response.data;
            subscription.variants = _.sortBy(subscription.variants, (v) => v.max_devices);
            resolve(subscription);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
};
