<template>
  <section>
      <img src="../../src/assets/img/fonetti_404.png" id="error">
      <h1>Oops!</h1>
      <h2>We haven't been able to find the page you are looking for.</h2>
      <p><strong>Error code: 404</strong></p>
      <p v-if="isLoggedIn">Here are some useful links instead:</p>
      <p v-else>Here is a useful link instead:</p>
      <ul v-if="isLoggedIn">
        <router-link to="/my/details" class="sidebar__link" active-class="sidebar__link--active" tag="li"><a>Account</a></router-link>
        <router-link to="/my/subscriptions" class="sidebar__link" active-class="sidebar__link--active" tag="li"><a>Subscriptions</a></router-link>
        <router-link to="/my/payments" class="sidebar__link" active-class="sidebar__link--active" tag="li"><a>Payments</a></router-link>
      </ul>
      <ul v-else>
        <router-link to="/" class="sidebar__link" active-class="sidebar__link--active" tag="li"><a>Login / Register</a></router-link>
      </ul>
  </section>
</template>

<script>
export default {
  name: 'not-found',
  computed: {
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },
  },
};
</script>

<style lang="scss" scoped>
  li {
    list-style-type: none;
  }

  #error {
    width: 500px;
    margin-left: calc(50% - 250px);
  }
</style>
