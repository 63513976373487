export default {
  methods: {
    validatePayment(paymentIntent, cardId) {
      // we need to check here if authentication is needed
      return new Promise((resolve, reject) => {
        if (paymentIntent === null) {
          resolve('succeeded');
        }
        if (paymentIntent && paymentIntent.status === 'requires_action') {
          this.stripe.confirmCardPayment(paymentIntent.client_secret, {
            payment_method: cardId,
          }).then((result) => {
            if (result.error) {
              reject(new Error(result.error.message));
            } else {
              resolve(paymentIntent.status);
            }
          });
        } else if (paymentIntent && paymentIntent.status === 'requires_payment_method') {
          reject(new Error('Your card was declined. Please use another payment method.'));
        } else {
          resolve(paymentIntent.status);
        }
      });
    },
  },
};
