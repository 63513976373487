<template>
  <div class="stat-wrapper">
    <fa :icon="icon" class="stat-icon"></fa>
    <span class="stat-details">{{ stat.count }} {{ stat.label }}</span>
  </div>
</template>
<script>
export default {
  props: ['icon', 'stat'],
};
</script>
<style lang="scss" scoped>
  .stat-wrapper {
    color: #7c3a00;
  }
  .stat-details {
    font-weight: 600;
    padding-left: 0.5em;
  }
</style>
<style lang="scss">
  .stat-icon {
    opacity: 0.8;
  }
</style>
