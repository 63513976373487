export default {
  serverURI: process.env.VUE_APP_API_URL,
  adminPanelURL: process.env.VUE_APP_ADMIN_PANEL_URL,
  webPortalURL: process.env.VUE_APP_WEB_PORTAL_URL,
  mainWebsiteURL: process.env.VUE_APP_MAIN_WEBSITE_URL,
  gtm: {
    enabled: (process.env.VUE_APP_GTM_ID || '') !== '',
    id: process.env.VUE_APP_GTM_ID || '',
    auth: process.env.VUE_APP_GTM_AUTH || '',
    preview: process.env.VUE_APP_GTM_PREVIEW || '',
  },
  releaseStage: process.env.VUE_APP_RELEASE_STAGE || 'production',
};
