import FloatLabel from 'vue-float-label/components/FloatLabel';

export default {
  components: {
    FloatLabel,
  },
  data() {
    return {
      isPasswordVisible: false,
      loginPasswordIcon: 'eye',
      registerPasswordIcon: 'eye',
    };
  },
  methods: {
    validateForm() {
      this.$emit('validated');
    },
    update(key, value) {
      this.$emit('input', { ...this.value, [key]: value });
    },
    togglePasswordVisibility(source) {
      let input = null;
      switch (source) {
        case 'register':
          this.registerPasswordIcon = this.registerPasswordIcon === 'eye' ? 'eye-slash' : 'eye';
          input = document.getElementById('password-register');
          break;
        case 'login':
        default:
          this.loginPasswordIcon = this.loginPasswordIcon === 'eye' ? 'eye-slash' : 'eye';
          input = document.getElementById('password-login');
          break;
      }

      if (input.type === 'password') {
        input.type = 'text';
      } else {
        input.type = 'password';
      }
    },
  },
};
