export default {
  methods: {
    getUser(packageId = null) {
      let url = 'users/me?include=subscriptions';
      if (packageId !== null) {
        url += `&package_id=${packageId}`;
      }
      return new Promise((resolve, reject) => {
        this.$api.get(url)
          .then((response) => {
            const { user } = response.data;
            if (user) {
              this.$store.commit('SET_USER', user);
            }
            resolve(user);
          })
          .catch((error) => reject(error));
      });
    },
  },
};
