<template>
    <div class="reader-card" ref="readerCard">
        <div class="avatar">
            <img :src="`/img/avatars/${reader.avatar}.png`">
        </div>
        <h3 class="name">{{reader.name || ''}}</h3>
        <div class="row gy-2 gx-3 mb-3">
            <div class="col-6">
                <StatWrapper :icon="'book'" :stat="{count: reader.progress.books_read, label: 'Books'}"></StatWrapper>
            </div>
            <div class="col-6">
                <StatWrapper :icon="'star'" :stat="{count: reader.progress.stars, label: 'Stars'}"></StatWrapper>
            </div>
            <div class="col-12">
                <StatWrapper :icon="'clock'" :stat="{count: reader.progress.reading_time, label: ''}"></StatWrapper>
            </div>
        </div>
        <div class="d-flex justify-content-center">
            <router-link class="btn btn-primary" :to="{path: `/my/readers/${reader.id}`}">More Details</router-link>
        </div>
    </div>
</template>
<script>
import StatWrapper from './StatWrapper';

export default {
  props: ['reader'],
  components: {
    StatWrapper,
  },
  mounted() {
    if (!this.$store.state.readerSummary[this.reader.id]) {
      const params = {
        start_time: new Date('2021-01-01'),
        end_time: new Date(),
      };
      this.$api.get(`statistics/readers/${this.reader.id}/progress`, { params })
        .then((response) => {
          const progress = response.data;
          this.reader.setProgress(progress);
          this.$store.commit('SET_READER_SUMMARY', { id: this.reader.id, progress });
        });
    } else {
      this.reader.setProgress(this.$store.state.readerSummary[this.reader.id]);
    }
  },
};
</script>
<style lang="scss" scoped>
    .reader-card {
        margin-top: calc(37.5px + 1rem);
        background-color: #ffce4e;
        padding: calc(37.5px + .5rem) 1rem 1rem;
        position: relative;
        border-radius: 15px;
        box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
        .btn:hover {
            color: white;
        }
        .avatar {
            height: 80px;
            width: 80px;
            display: block;
            background-color: #ffce4e;
            border-radius: 50%;
            position: absolute;
            top: -37.5px;
            left: calc(50% - 37.5px);
            display: flex;
            justify-content: center;
            align-items: center;
            img {
                width: 70px;
                height: 70px;
                object-fit: contain;
            }
        }
        .name, .stat-details {
            color: #7c3a00;
        }
        .name {
            text-align: center;
            line-height: 1.2;
        }
        .stat-details {
            font-weight: bold;
        }
    }
</style>
