<template>
  <section class="add-reader-page">
    <flash-message></flash-message>
    <h2 class="table-title">Edit Reader</h2>
    <div class="mt-4">
      <ReaderForm v-model="reader" @deleteReader="handleDelete"
        @validated="modifyReader" :showDelete="true"></ReaderForm>
    </div>
    <DeleteReaderModal :modal="modal" :reader="currentReader" @deleteConfirmed="deleteProfile"></DeleteReaderModal>
  </section>
</template>
<script>
import ReaderForm from '@/components/forms/ReaderForm';
import DeleteReaderModal from '@/components/DeleteReaderModal';
import ReaderMixin from '@/components/mixins/ReaderMixin';
import ModalJS from '@/components/classes/Modal';

export default {
  mixins: [ReaderMixin],
  components: {
    ReaderForm,
    DeleteReaderModal,
  },
  data() {
    return {
      modal: new ModalJS({
        deleteReader: false,
      }),
      reader: {
        name: '',
        age: null,
      },
    };
  },
  methods: {
    modifyReader() {
      this.updateReader(this.reader, this.currentReader.id);
    },
    handleDelete() {
      this.modal.show('deleteReader');
    },
    deleteProfile() {
      this.destroyReader(this.currentReader.id);
    },
  },
  mounted() {
    if (this.currentReader) {
      this.reader.name = this.currentReader.name;
      this.reader.age = this.currentReader.age;
    }
  },
};
</script>
