export default {
  mounted() {
    this.$nextTick(() => {
      this.isOnMobile();
    });
    window.addEventListener('resize', this.isOnMobile);
  },
  methods: {
    isOnMobile() {
      this.$store.commit('SHOW_LAYOUT', false);
      const mobileOnly = this.$refs['mobile-only'];
      if (typeof mobileOnly === 'object') {
        if (window.getComputedStyle(this.$refs['mobile-only']).display !== 'none') {
          this.$store.commit('IS_ON_MOBILE', true);
        } else {
          this.$store.commit('IS_ON_MOBILE', false);
        }
      }
      this.$store.commit('SHOW_LAYOUT', true);
    },
  },
};
