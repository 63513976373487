<template>
  <div class="product-box__container">
    <div class="product-box">
      <div class="product-box__row">
        <div class="product-box__cover">
          <img :src="coverImage" :alt="product.title">
          <div class="product-box__cover-footer" v-if="hasSingles">
            <p><span class="product-box__cover-footer__number">{{ product.singles.length }}</span> title can be purchased individually</p>
            <button class="btn btn-primary" @click="showPurchasableSingleBooksList = true">View</button>
          </div>
        </div>
        <div class="product-box__wrapper">
          <h3>{{ product.title }}</h3>
          <div class="product-box__content">
            <div class="product-box__description">{{ product.description }}</div>
            <div class="product-box__footer">
              <v-button class="btn btn-primary" group="product" @click="manage(product)" v-if="!product.has_purchased">{{ buttonText }}</v-button>
              <span class="product-box__footer-purchased" v-if="product.has_purchased">Purchased</span>
            </div>
          </div>
        </div>
      </div>
      <transition name="fadeSlideUp">
      <div v-if="showPurchasableSingleBooksList" class="product-box__modal">
        <div class="product-box__modal-header">
          <p>Available titles for individual purchase</p>
          <button type="button" class="product-box__modal-button-close" data-dismiss="modal" aria-label="Close" @click="showPurchasableSingleBooksList = false"><fa icon="times-circle"></fa></button>
        </div>
        <div class="product-box__modal-content">
          <ul>
            <li v-for="(single, index) in product.singles" :key="single.id" :class="{'last-item': index === product.singles.length - 1}"><span>{{ single.title }}</span> <v-button v-if="!single.has_purchased" class="btn btn-primary" @click="manage(single)" group="product">{{ buttonText }}</v-button><i v-if="single.has_purchased">purchased</i></li>
          </ul>
        </div>
      </div>
      </transition>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import slug from 'slug';
import Button from '@/components/utils/Button';

export default {
  name: 'product-box',
  props: {
    isCurrent: {
      required: false,
      default: false,
      type: Boolean,
    },
    product: {
      required: true,
      type: Object,
    },
  },
  components: {
    'v-button': Button,
  },
  data() {
    return {
      showPurchasableSingleBooksList: false,
    };
  },
  methods: {
    manage(object) {
      let url = slug(object.title);
      url += `-${object.id}`;
      this.$router.push({ name: 'product', params: { slug: url } });
    },
  },
  computed: {
    coverImage() {
      return !_.isEmpty(this.product.cover_image) ? this.product.cover_image : '/img/missing_product_cover.png';
    },
    buttonText() {
      return 'Purchase';
    },
    hasSingles() {
      return this.product.singles && this.product.singles.length > 0;
    },
  },
};
</script>
