<template>
  <ValidationObserver v-slot="{ handleSubmit }" slim>
    <form ref="observer" @submit.prevent="handleSubmit(validateForm)" class="form-guest">
      <ValidationProvider rules="required|email" name="Email" v-slot="{ errors }" tag="div" class="form-guest__group">
        <float-label>
        <input type="text" name="email" placeholder="Email" :value="value.email" @input="update('email', $event.target.value)">
        </float-label>
        <span v-show="errors" class="form-guest__errors">{{ errors[0] }}</span>
      </ValidationProvider>
      <div class="row">
        <div class="col-md-6">
          <ValidationProvider rules="required|min:2|alpha_dash" name="First Name" v-slot="{ errors }" tag="div" class="form-guest__group">
            <float-label>
              <input type="text" name="first_name" placeholder="Firstname" :value="value.first_name" @input="update('first_name', $event.target.value)">
            </float-label>
            <span v-show="errors" class="form-guest__errors">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>
        <div class="col-md-6">
          <ValidationProvider rules="required|min:2|alpha_dash" name="Last Name" v-slot="{ errors }" tag="div" class="form-guest__group">
          <float-label>
              <input type="text" name="last_name" placeholder="Surname" :value="value.last_name" @input="update('last_name', $event.target.value)">
            </float-label>
            <span v-show="errors" class="form-guest__errors">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>
      </div>
      <ValidationProvider rules="required|min:7" name="Password" v-slot="{ errors }" tag="div" class="form-guest__group">
      <float-label>
          <input type="password" name="password" placeholder="Password" :value="value.password" @input="update('password', $event.target.value)" id="password-register">
          <fa :icon="registerPasswordIcon" @click="togglePasswordVisibility('register')" class="form-guest__password-icon"></fa>
        </float-label>
        <span v-show="errors" class="form-guest__errors">{{ errors[0] }}</span>
      </ValidationProvider>
      <div class="form-guest__footer">
        <ValidationProvider :rules="{ required: { allowFalse: false } }" v-slot="{ errors }" tag="div" class="form-guest__checkbox">
          <input id="privacy" name="privacy" type="checkbox" class="checkbox-v2 em2" :value="value.privacy" @change="update('privacy', $event.target.checked)">
          <label for="privacy" class="form-guest__label form-guest__label--small" :class="{'form-guest__label--invalid': errors[0]}"><span>I agree to the <a href="https://www.fonetti.com/terms-of-use/" target="_blank">Terms & Conditions</a> and have read the
            <a href="https://www.fonetti.com/privacy-policy/" target="_blank">Privacy Policy</a></span></label>
        </ValidationProvider>
          <input id="receive_marketing_emails" name="receive_marketing_emails" type="checkbox" class="checkbox-v2 em2" :value="value.receive_marketing_emails" @input="update('receive_marketing_emails', $event.target.checked)">
          <label for="receive_marketing_emails" class="form-guest__label form-guest__label--small">I want to be kept up to date with new features and special promotions</label>
      </div>
      <v-button class="btn btn-primary mt-40" group="auth">create my account</v-button>
    </form>
  </ValidationObserver>
</template>

<script>
import FormMixin from '@/components/mixins/FormMixin';
import Button from '@/components/utils/Button';

export default {
  name: 'register-form',
  mixins: [FormMixin],
  components: {
    'v-button': Button,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
};
</script>
