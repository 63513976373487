<template>
  <ValidationObserver v-slot="{ handleSubmit }" slim>
    <form @submit.prevent="handleSubmit(validateForm)" class="form-guest">
      <ValidationProvider rules="required|min:7" name="Password" v-slot="{ errors }" tag="div"
                          class="form-guest__group">
        <float-label>
          <input type="password" name="password" id="password" placeholder="Password" :value="value.password"
                 @input="update('password', $event.target.value)">
        </float-label>
        <span v-show="errors" class="form-guest__errors">{{ errors[0] }}</span>
      </ValidationProvider>
      <v-button class="btn btn-primary" group="password">Submit</v-button>
    </form>
  </ValidationObserver>
</template>

<script>
import FormMixin from '@/components/mixins/FormMixin';
import Button from '@/components/utils/Button';

export default {
  name: 'reset-password-form',
  mixins: [FormMixin],
  components: {
    'v-button': Button,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
};
</script>
