<template>
  <footer class="footer-mobile">
    <!-- <newsletter-modal :modal="modal"></newsletter-modal>
    <v-button class="newsletter-button" @click="modal.show('newsletter')">
      <span>Available for iPad only</span>
      Register your interest in other platforms
    </v-button> -->
    <div class="appstore-badges">
      <a href="https://apps.apple.com/app/fonetti/id1332609392" target="_blank"><img src="../assets/img/app-store.svg" alt="Download on the app store"></a>
      <a href="https://play.google.com/store/apps/details?id=com.auristech.fonetti" target="_blank"><img src="../assets/img/google-play-badge.svg" alt="Download on the play store"></a>
    </div>
    <div class="footer-mobile__logo">
      <img src="../assets/img/logo-footer.png" alt="empowered by Auris">
    </div>
  </footer>
</template>

<script>
import ModalJS from '@/components/classes/Modal';
// import Button from '@/components/utils/Button';
// import NewsletterModal from '@/components/NewsletterModal';

export default {
  name: 'footer-mobile',
  components: {
    // 'v-button': Button,
    // NewsletterModal,
  },
  data() {
    return {
      modal: new ModalJS({
        newsletter: false,
      }),
    };
  },
};
</script>
