<template>
  <div id="menu-mobile-wrapper">
    <div id="menu-mobile">
      <ul>
        <li class="menu-mobile__link menu-mobile__link--bt menu-mobile__link--bb"><a href="https://fonetti.com">Fonetti.com</a></li>
        <router-link to="/my/dashboard" class="menu-mobile__link" active-class="menu-mobile__link--active" tag="li"><a @click="$emit('click')">Dashboard</a></router-link>
        <router-link to="/my/details" class="menu-mobile__link" active-class="menu-mobile__link--active" tag="li"><a @click="$emit('click')">Account</a></router-link>
        <router-link to="/my/subscriptions" class="menu-mobile__link" active-class="menu-mobile__link--active" tag="li"><a @click="$emit('click')">Subscriptions</a></router-link>
        <router-link to="/my/products" class="menu-mobile__link" active-class="menu-mobile__link--active" tag="li"><a @click="$emit('click')">My Books</a></router-link>
        <router-link to="/my/payments" class="menu-mobile__link" active-class="menu-mobile__link--active" tag="li"><a @click="$emit('click')">Payments</a></router-link>
        <li class="sidebar__link"><a @click="logout()">Logout</a></li>
        <li class="menu-mobile__link menu-mobile__link--bt"><a href="https://www.fonetti.com/get-in-touch/">Contact Us</a></li>
        <li class="menu-mobile__link menu-mobile__link--bb"><a href="https://www.fonetti.com/privacy-policy/">Privacy Policy</a></li>
      </ul>
      <img src="../assets/img/logo-footer.png" alt="empowered by Auris">
    </div>
  </div>
</template>

<script>
import AuthMixin from '@/components/mixins/AuthMixin';

export default {
  name: 'menu-mobile-mobile',
  mixins: [AuthMixin],
};
</script>
