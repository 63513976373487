<template>
  <section class="add-reader-page">
    <flash-message></flash-message>
    <h2 class="table-title">Add Reader</h2>
    <div class="mt-4">
      <ReaderForm v-model="reader" @validated="addReader"></ReaderForm>
    </div>
  </section>
</template>
<script>
import ReaderForm from '@/components/forms/ReaderForm';
import ReaderMixin from '@/components/mixins/ReaderMixin';

export default {
  mixins: [ReaderMixin],
  components: {
    ReaderForm,
  },
  data() {
    return {
      reader: {
        age: null,
        name: '',
      },
    };
  },
  methods: {
    addReader() {
      this.createReader(this.reader);
    },
  },
};
</script>
